<template>
  <template v-if="store.brandName === ''">
    <template v-if="store.brandQueryFetching">
      <FlexRow class="justify-center">
        <h3 class="text-center text-lg font-poppinsBold">
          <span class="text-mulah-red font-bold font-inter">Simple</span
          ><span class="text-primary-black font-bold font-inter">Loyalty</span>
        </h3>
      </FlexRow>
      <FlexRow class="justify-center">
        <FlexCol class="grid h-screen place-items-center">
          <Spinner :width="16" :height="16" />
        </FlexCol>
      </FlexRow>
    </template>
    <template v-else>
      <LandingSearch />
    </template>
  </template>
  <template v-else>
    <FlexRow>
      <FlexCol class="container min-h-screen overflow-y-auto">
        <template v-if="!loggedIn">
          <RewardsPanel
            :rewards="store.rewards"
            :user-points="userPoints"
            id="default-side-padding"
          />
          <Links :links="portalLinks" id="default-side-padding" />
        </template>
        <template v-else>
          <PostLogin />
        </template>
      </FlexCol>
    </FlexRow>
    <FlexRow v-if="!loggedIn" class="pt-1"> </FlexRow>
    <MobileFooter class="mx-0" v-if="loggedIn" :active="'Home'" />
  </template>
</template>

<script setup>
import Button from '@/components/uielements/Button/Button.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import MobileFooter from '@/layout/MobileFooter/Index.vue'
import IconCone from '@/components/icons/IconCone.vue'
import RewardsPanel from '@/components/reusable/RewardsPanel.vue'
import Spinner from '@/components/reusable/Spinner.vue'
import LandingSearch from '@/pages/LandingSearch/Index.vue'
import Links from './Links.vue'
import GiveawayPreview from './GiveawayPreview.vue'
import PostLogin from '@/pages/PostLogin/Index.vue'
import { useRouter } from 'vue-router'
import { useBrandStore, useBrandPortalStore } from '../../stores/store'
import { usePhoneStore } from '../../stores/store'
import { ref, computed, onMounted, watch } from 'vue'

const router = useRouter()
const store = useBrandStore()
const brandPortalStore = useBrandPortalStore()
const phoneStore = usePhoneStore()
const userPoints = ref(0)
const portalLinks = ref([])

const processLinks = (links) => {
  if (Array.isArray(links) && links.length > 0) {
    portalLinks.value = links
  }
}

onMounted(async () => {
  if (brandPortalStore.links) {
    processLinks(brandPortalStore.links)
  }
})

watch(
  () => brandPortalStore.links,
  (newValue) => {
    if (newValue) {
      processLinks(newValue)
    }
  }
)

const loggedIn = computed(() => {
  return phoneStore.isLogin
})

const handleNextPage = () => {
  if (phoneStore.isLogin) {
    router.push({
      name: 'QualifiedRedemption'
    })
  } else {
    router.push({
      name: 'CheckPointsRewards'
    })
  }
}
</script>
