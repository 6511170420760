<template>
  <FlexCol class="mt-5 relative">
    <p class="text-2xl font-bold" :style="brandPortalStore.primaryTextColorStyle">Select Store</p>
    <p class="text-md text-gray-600" :style="brandPortalStore.secondaryTextColorStyle">
      Rewards limited to 1 redemption per store
    </p>
  </FlexCol>

  <FlexCol class="relative mt-8 mb-28">
    <button
      :style="{ borderColor: brandPortalStore.primaryTextColorStyle.color }"
      class="ps-3 text-start text-md font-semibold flex items-center justify-between border px-0 p-4 rounded-l"
      @click="showDropdown = !showDropdown"
    >
      <span :style="brandPortalStore.primaryTextColorStyle">
        <IconShop class="inline mb-1 me-1" style="width: 20px; height: 20px" />
        {{ selectedOutlet.name }}
      </span>
      <span :style="brandPortalStore.primaryTextColorStyle" class="ms-3 pr-3">
        <IconChevronDown style="width: 12px; height: 12px" />
      </span>
    </button>
    <div
      v-if="showDropdown"
      style="z-index: 2"
      class="origin-top-left absolute top-[50px] right-0 mt-2 w-50 border rounded-md shadow-md bg-white"
    >
      <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
        <Button
          variant="custom"
          class="block px-4 py-2 text-sm text-gray-500 outline-none bg-transparent"
          disabled
        >
          Select Store
        </Button>
        <template v-for="outlet in enabledOutlets" :key="outlet.outletId">
          <Button
            :disabled="isOutletReviewed(parseInt(outlet.outletId))"
            variant="custom"
            class="px-4 py-2 text-sm outline-none bg-transparent focus:outline-none w-full justify-between items-center whitespace-nowrap"
            :class="{
              'text-mulah-red': outlet.outletId === selectedOutlet.id,
              'text-[#595959]': outlet.outletId !== selectedOutlet.id
            }"
            :style="brandPortalStore.primaryTextColorStyle"
            @click="selectOutlet(outlet)"
          >
            {{ outlet.outletName }}
            <span v-if="isOutletReviewed(parseInt(outlet.outletId))" class="text-gray-500"
              >(Reviewed)</span
            >
            <span v-if="outlet.outletId === selectedOutlet.id" class="ms-1 mt-0.5 text-mulah-red">
              <IconCheckFill />
            </span>
          </Button>
        </template>
      </div>
    </div>
  </FlexCol>
</template>

<script setup>
import { ref, computed } from 'vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import IconChevronDown from '@/components/icons/IconChevronDown.vue'
import Button from '@/components/uielements/Button/Button.vue'
import IconCheckFill from '@/components/icons/IconCheckFill.vue'
import IconShop from '@/components/icons/IconShop.vue'
import { useBrandPortalStore } from '@/stores/store.js'

const brandPortalStore = useBrandPortalStore()

const props = defineProps({
  enabledOutlets: Array,
  reviewedOutlets: Array
})

const showDropdown = ref(false)

const selectedOutlet = ref({ id: null, name: 'Select Store' })

const emit = defineEmits(['outlet-selected'])

const selectOutlet = (outlet) => {
  selectedOutlet.value = { id: outlet.outletId, name: outlet.outletName }
  emit('outlet-selected', selectedOutlet.value)
  showDropdown.value = !showDropdown
}

const isOutletReviewed = (outletId) => {
  return props.reviewedOutlets.includes(outletId)
}
</script>
