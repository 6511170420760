<template>
  <div id="app">
    <MobileLayout v-if="store.brandName !== ''" :title="store.brandName" />
    <div id="container" :style="{ padding: containerPadding }">
      <router-view v-if="!checkLoading" />
      <FlexRow v-if="checkLoading" class="justify-center">
        <FlexCol class="grid h-screen place-items-center">
          <Spinner :width="16" :height="16" />
        </FlexCol>
      </FlexRow>
    </div>
  </div>
</template>

<script setup>
import Spinner from '@/components/reusable/Spinner.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import FlexCol from '@/components/layout/FlexCol.vue'

import { watch, computed, onMounted, ref } from 'vue'
import { useMutation, useQuery } from '@urql/vue'
import {
  useBrandStore,
  usePhoneStore,
  useBrandPortalStore,
  useNotificationConfig
} from './stores/store'
import {
  CHECK_LOGIN,
  GET_BRAND_SLUG,
  CREATE_WEBSITE_VISIT,
  CREATE_SLUG_PROSPECT,
  BRAND_PORTAL_SETTINGS,
  SL_APP_PLAN_NOTIFICATION_CONFIG
} from '@/constants/graphql.js'
import { useRoute, useRouter } from 'vue-router'
import MobileLayout from '@/layout/MobileLayout/Index.vue'

const slug = ref(null)

const route = useRoute()
const router = useRouter()
const store = useBrandStore()
const phoneStore = usePhoneStore()
const portalStore = useBrandPortalStore()
const checkLoading = ref(true)

const brandId = computed(() => {
  return store.brandId;
})

const pausePortalQuery = computed(() => {
  return store.brandId === "";
})

onMounted(() => {
  const slug = $cookies.get('slSlug')
  if (slug) {
    store.$patch({ slugUrl: slug })
  }
})

const { executeMutation: createWebsiteVisit } = useMutation(CREATE_WEBSITE_VISIT)
const { executeMutation: createSlugProspect } = useMutation(CREATE_SLUG_PROSPECT)

const isLogin = useQuery({ query: CHECK_LOGIN })
const brandSlug = useQuery({
  query: GET_BRAND_SLUG,
  variables: {
    phoneNumber: '',
    sort: 'cost',
    direction: 'ASC',
    filters: {
      status: 'active',
      title: '',
      specification: 'Point Reward'
    },
    slug: slug
  },
  pause: computed(() => !slug.value)
})

const brandPortalQuery = useQuery({
  query: BRAND_PORTAL_SETTINGS,
  variables: {
    brandId: brandId
  },
  pause: pausePortalQuery
})

const containerPadding = computed(() => {
  return route.name === 'Login' ||
    route.name === 'Memberships' ||
    route.name === 'QualifiedRedemption' ||
    route.name === 'CheckPointsRewards' ||
    route.name === 'LandingSearch' ||
    route.name === 'SpinTheWheelTnc'
    ? '0'
    : '1.25rem'
})

const checkLbColorSpecification = (type, newColor, gradientColor) => {
  if (type === 'flat') {
    document.body.style.backgroundColor = newColor
    document.getElementById('background-image').style.display = 'none'
  } else {
    document.body.style.backgroundImage = gradientColor
    document.getElementById('background-image').style.display = 'none'
  }
}

const checkBackgroundColorSpecification = (spec, bgColor, bgGradient) => {
  if (spec === 'flat') {
    container.style.background = bgColor
  } else {
    container.style.background = bgGradient
  }
}

watch(isLogin.fetching, (fetchStatus) => {
  if (!fetchStatus) {
    let loginStatus = false
    let phoneNumber = ''

    if (isLogin.data.value.slAppCustomer !== null) {
      loginStatus = true
      phoneNumber = isLogin.data.value.slAppCustomer.phoneNumber
    }
    phoneStore.$patch({
      isLogin: loginStatus,
      phoneNumberWithPrefix: phoneNumber
    })

    checkLoading.value = false
  }
})

watch(
  () => route.params,
  (param) => {
    const slugCookie = $cookies.get('slSlug')
    if (param.slug && param.slug !== '') {
      slug.value = param.slug
      store.$patch({ slugUrl: route.params.slug })
      $cookies.set('slSlug', slug.value, '1y', null, process.env.VUE_APP_COOKIE_DOMAIN, true)
    } else if (slugCookie) {
      slug.value = slugCookie
      store.$patch({ slugUrl: slugCookie })
    }
  }
)

watch(brandSlug.fetching, async (fetchStatus) => {
  store.brandQueryFetching = fetchStatus

  if (!fetchStatus) {
    if (!brandSlug.data.value.slBrandWithSlug) {
      store.$reset()
      router.push({ path: '/', replace: true })
    } else {
      const {
        id,
        name,
        countryCode,
        websiteUrl,
        deliveryUrl,
        promotionUrl,
        termsAndConditions,
        promotions,
        giveaways,
        membershipDetails
      } = brandSlug.data.value.slBrandWithSlug

      store.$patch({
        brandId: id,
        brandName: name,
        countryCode: countryCode,
        giveaways: giveaways,
        tnc: termsAndConditions,
        rewards: promotions,
        memberships: membershipDetails
      })

      store.links[0].url = websiteUrl
      store.links[1].url = deliveryUrl
      store.links[2].url = promotionUrl

      if (phoneStore.isLogin) {
        await createWebsiteVisit({
          id: store.brandId
        })

        await createSlugProspect({
          slug: store.slugUrl
        })
      }
    }
  }
})

watch(brandPortalQuery.fetching, async (fetchStatus) => {
  if (!fetchStatus) {
    if (brandPortalQuery.data.value.brandPortalSetting) {
      const brandPortalSettings = brandPortalQuery.data.value.brandPortalSetting

      if (brandPortalSettings) {
        const {
          logoUrl,
          colorPalette,
          coverSpecification,
          coverColor,
          coverPhotoUrl,
          carouselTitle,
          carouselSpecification,
          carouselSpacesCustom,
          carouselSpacesInsta,
          links,
          lbSpecification,
          lbColor,
          lbGradient,
          lbColorSpecification,
          lbPhotoUrl,
          lbOpacity,
          membersInfoSpecification,
          membersInfoColor,
          membersInfoTextColor,
          membersInfoPhotoUrl,
          membersInfoGradient,
          membersInfoOpacity,
          membersInfoColorSpecification,
          topNavbarGradient,
          topNavbarSpecification,
          topNavbarColorSpecification,
          topNavbarOpacity,
          topNavbarTextColor,
          topNavbarColor,
          topNavbarPhotoUrl,
          generalBackgroundColor,
          generalBackgroundGradient,
          generalBackgroundColorSpecification,
          primaryTextColor,
          secondaryTextColor,
          textLinkColor,
          botNavbarBackgroundColor,
          botNavbarDefaultColor,
          botNavbarSelectedColor,
          primaryBtnColor,
          primaryBtnTextColor,
          primaryBtnBorderColor,
          secondaryBtnColor,
          secondaryBtnTextColor,
          secondaryBtnBorderColor,
          storeListCardColor
        } = brandPortalSettings

        portalStore.$patch({
          colorPalette: colorPalette,
          logoUrl: logoUrl,
          coverSpecification: coverSpecification,
          coverColor: coverColor,
          coverPhotoUrl: coverPhotoUrl,
          carouselTitle: carouselTitle,
          carouselSpecification: carouselSpecification,
          carouselSpacesCustom: carouselSpacesCustom,
          carouselSpacesInsta: carouselSpacesInsta,
          links: links,
          lbSpecification: lbSpecification,
          lbColor: lbColor,
          lbGradient: lbGradient,
          lbColorSpecification: lbColorSpecification,
          lbPhotoUrl: lbPhotoUrl,
          lbOpacity: lbOpacity,
          membersInfoSpecification: membersInfoSpecification,
          membersInfoColor: membersInfoColor,
          membersInfoTextColor: membersInfoTextColor,
          membersInfoPhotoUrl: membersInfoPhotoUrl,
          membersInfoGradient: membersInfoGradient,
          membersInfoOpacity: membersInfoOpacity,
          membersInfoColorSpecification: membersInfoColorSpecification,
          topNavbarGradient: topNavbarGradient,
          topNavbarSpecification: topNavbarSpecification,
          topNavbarColorSpecification: topNavbarColorSpecification,
          topNavbarOpacity: topNavbarOpacity,
          topNavbarTextColor: topNavbarTextColor,
          topNavbarColor: topNavbarColor,
          topNavbarPhotoUrl: topNavbarPhotoUrl,
          generalBackgroundColor: generalBackgroundColor,
          generalBackgroundGradient: generalBackgroundGradient,
          generalBackgroundColorSpecification: generalBackgroundColorSpecification,
          primaryTextColor: primaryTextColor,
          secondaryTextColor: secondaryTextColor,
          textLinkColor: textLinkColor,
          botNavbarBackgroundColor: botNavbarBackgroundColor,
          botNavbarDefaultColor: botNavbarDefaultColor,
          botNavbarSelectedColor: botNavbarSelectedColor,
          primaryBtnColor: primaryBtnColor,
          primaryBtnTextColor: primaryBtnTextColor,
          primaryBtnBorderColor: primaryBtnBorderColor,
          secondaryBtnColor: secondaryBtnColor,
          secondaryBtnTextColor: secondaryBtnTextColor,
          secondaryBtnBorderColor: secondaryBtnBorderColor,
          storeListCardColor: storeListCardColor
        })
      }
    }
  }
})

const formatToOpacity = (range) => {
  return parseInt(range) / 100
}

watch(
  () => [
    portalStore.lbSpecification,
    portalStore.lbColor,
    portalStore.lbColorSpecification,
    portalStore.lbGradient,
    portalStore.lbPhotoUrl,
    portalStore.lbOpacity
  ],
  ([spec, newColor, colorSpec, gradientColor, newUrl, opacity]) => {
    if (spec === 'photo') {
      document.body.style.backgroundColor = ''
      const img = document.getElementById('background-image')
      img.src = newUrl
      img.style.display = 'block'
      const opacityValue = formatToOpacity(opacity)
      img.style.opacity = opacityValue
    } else {
      checkLbColorSpecification(colorSpec, newColor, gradientColor)
    }
  },
  { immediate: true }
)

watch(
  () => [
    portalStore.generalBackgroundColorSpecification,
    portalStore.generalBackgroundColor,
    portalStore.generalBackgroundGradient
  ],
  ([spec, bgColor, bgGradient]) => {
    const container = document.getElementById('container')
    if (container) {
      checkBackgroundColorSpecification(spec, bgColor, bgGradient)
    } else {
      console.warn('Element with id "container" not found')
    }
  },
  { immediate: true }
)

const notificationConfig = useNotificationConfig()
const notificationQuery = useQuery({ query: SL_APP_PLAN_NOTIFICATION_CONFIG })

watch(notificationQuery.fetching, (fetchStatus) => {
  if (!fetchStatus) {
    const filteredConfigs = notificationQuery.data.value.slAppPlanNotificationConfigs.filter(
      (config) =>
        config.specification === 'app_paid_campaign' ||
        config.specification === 'app_free_campaign' ||
        config.specification === 'app_paid_verification' ||
        config.specification === 'app_free_verification'
    )
    notificationConfig.$patch({
      notificationConfig: filteredConfigs
    })
  }
})
</script>
