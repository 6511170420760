<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    :fill="icon"
    class="bi bi-stopwatch"
    viewBox="-8 -8 32 32"
  >
    <circle cx="8" cy="9" r="15" :fill="bgColor" fill-opacity="0.3" />
    <circle cx="8" cy="9" r="13" :fill="bgColor" />

    <path d="M8.5 5.6a.5.5 0 1 0-1 0v2.9h-3a.5.5 0 0 0 0 1H8a.5.5 0 0 0 .5-.5z" />
    <path
      d="M6.5 1A.5.5 0 0 1 7 .5h2a.5.5 0 0 1 0 1v.57c1.36.196 2.594.78 3.584 1.64l.012-.013.354-.354-.354-.353a.5.5 0 0 1 .707-.708l1.414 1.415a.5.5 0 1 1-.707.707l-.353-.354-.354.354-.013.012A7 7 0 1 1 7 2.071V1.5a.5.5 0 0 1-.5-.5M8 3a6 6 0 1 0 .001 12A6 6 0 0 0 8 3"
    />
  </svg>
</template>
<script setup>
import { computed } from 'vue'
import { useBrandPortalStore } from '@/stores/store.js'

const brandPortalStore = useBrandPortalStore()

const icon = computed(() => {
  return brandPortalStore.primaryButtonStyles.color
})

const bgColor = computed(() => {
  return brandPortalStore.primaryButtonStyles.backgroundColor
})
</script>
