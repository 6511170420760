<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <section class="sticky margin-0">
    <FlexRow
      class="justify-center w-full items-center relative"
      :style="{
        background: store.topNavbarSpecification === 'color' ? store.topNavbarColorSpec : null
      }"
    >
      <img
        v-if="store.topNavbarSpecification === 'photo'"
        :style="{
          opacity: `${store.topNavbarOpacity}%`,
          width: '100%'
        }"
        :src="store.topNavbarPhotoUrl"
      />
      <FlexCol
        class="align middle justify-center items-center"
        :style="{
          position: store.topNavbarSpecification === 'photo' ? 'absolute' : 'relative'
        }"
        style="z-index: 5"
      >
        <img
          v-if="store.logoUrl"
          :src="store.logoUrl"
          alt="logo"
          class="active-hover z-50"
          style="width: 2rem; height: 2rem; border-radius: 50%"
          @click="backToHome"
          draggable="false"
        />
        <h3
          v-else
          class="z-50 active-hover text-center text-lg font-poppinsBold"
          @click="backToHome"
          :style="store.topNavbarTextColorStyle"
        >
          {{ props.title }}
        </h3>
        <p
          class="z-50 text-secondary-black text-xs text-center mb-2"
          :style="store.topNavbarTextColorStyle"
        >
          Powered by
          <span class="text-mulah-red font-inter">Simple</span>
          <span class="text-black font-bold font-inter" :style="store.topNavbarTextColorStyle"
            >Loyalty</span
          >
        </p>
      </FlexCol>
      <div class="absolute right-0">
        <button
          type="button"
          class="text-white focus:outline-none me-2 z-50 relative"
          @click="showSideNav = true"
        >
          <BurgerIcon class="w-6 h-6" :style="store.topNavbarTextColorStyle" />
        </button>
      </div>
    </FlexRow>
  </section>
  <div class="min-h-fit min-w-full">
    <slot></slot>
  </div>
  <SideNav :showSideNav="showSideNav" @closeSideNav="showSideNav = false" />
  <slot name="footer"></slot>
</template>

<script setup>
import SideNav from './SideNav/SideNav.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import BurgerIcon from '@/components/icons/IconBurger.vue'
import { ref, computed } from 'vue'
import { useBrandPortalStore } from '@/stores/store.js'
import router from '@/router/index.js'

const store = useBrandPortalStore()
const showSideNav = ref(false)

const formatToOpacity = (range) => {
  return parseInt(range) / 100
}

const navbarOpacity = computed(() => {
  if (store.topNavbarSpecification === 'photo') {
    return store.topNavbarOpacity
  } else {
    return '1'
  }
})

const topNavbarBackground = computed(() => {
  return {
    url: `url(${store.topNavbarPhotoUrl}) no-repeat center`,
    opacity: formatToOpacity(store.topNavbarOpacity)
  }
})

const backToHome = () => {
  router
    .push({
      name: 'Login'
    })
    .catch((err) => {
      console.error('Navigation error:', err)
    })
}

const props = defineProps({
  title: String
})
</script>

<style scoped>
.active-hover {
  cursor: pointer;
}
</style>
