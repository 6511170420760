<template>
  <FlexRow class="justify-between w-full search-col">
    <FlexCol class="justify-center w-11/12">
      <div class="flex flex-row items-center justify-between mt-2 relative">
        <div class="w-full">
          <FormInput
            v-model="modelValue"
            class="rounded-md bg-gray-200"
            style="font-size: 0.875rem !important"
            placeholder="Search store"
            maxlength="25"
            id="search-bar-input"
          />
        </div>
        <div class="p-2 absolute right-2">
          <IconSearch class="w-4 h-4 text-primary-black cursor-pointer" />
        </div>
      </div>
    </FlexCol>
    <FlexCol class="w-1/12 items-end justify-center ms-2">
      <IconClose @click="clearField" class="cursor-pointer pt-1"></IconClose>
    </FlexCol>
  </FlexRow>
</template>
<script setup>
import FormInput from '@/components/uielements/FormInput/FormInput.vue'
import IconSearch from '@/components/icons/IconSearch.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import IconClose from '@/components/icons/IconClose.vue'

import { ref, watch, onMounted } from 'vue'
const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  clearField: {
    type: Function
  }
})

const emit = defineEmits(['update:modelValue'])
const modelValue = ref(props.modelValue)

watch(modelValue, (value) => {
  emit('update:modelValue', value)
})

function clearField() {
  modelValue.value = ''
}

onMounted(() => {
  const inputElement = document.getElementById('search-bar-input')
  if (inputElement) {
    inputElement.focus()
  }
})
</script>
