<template>
  <div>
    <button
      class="fb-button"
      :style="brandPortalStore.primaryButtonStyles"
      @click="logInWithFacebook"
    >
      Login with Facebook
    </button>
  </div>
</template>
<script setup>
import { defineProps, onMounted } from 'vue'
import { useBrandPortalStore } from '@/stores/store.js'

const brandPortalStore = useBrandPortalStore()

const props = defineProps({
  appId: {
    type: String,
    required: true
  },
  onSuccess: {
    type: Function,
    required: true
  },
  onFailure: {
    type: Function,
    required: true
  }
})

onMounted(() => {
  loadFacebookSDK(document, 'script', 'facebook-jssdk')
  initFacebook()
})

function logInWithFacebook() {
  window.FB.login(
    function (response) {
      if (response.authResponse) {
        props.onSuccess(response)
      } else {
        props.onFailure(response)
      }
    },
    { scope: 'email' }
  )
  return false
}

function initFacebook() {
  window.fbAsyncInit = function () {
    window.FB.init({
      appId: props.appId,
      cookie: true,
      version: 'v18.0'
    })
  }
}

function loadFacebookSDK(d, s, id) {
  var js,
    fjs = d.getElementsByTagName(s)[0]
  if (d.getElementById(id)) {
    return
  }
  js = d.createElement(s)
  js.id = id
  js.src = 'https://connect.facebook.net/en_US/sdk.js'
  fjs.parentNode.insertBefore(js, fjs)
  console.log('loaded')
}
</script>
<style scoped>
.fb-button {
  display: flex;
  color: white;
  font-size: 18px;
  background-color: #1967d2;
  border-radius: 8px;
  padding: 15px;
  cursor: pointer;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.fb-button:hover {
  background-color: #1656b5;
}
</style>
