<template>
  <Card class="wheel-container border-0 shadow-none min-h-screen" v-if="!showLimitReached">
    <CardBody>
      <FlexRow class="wheel-row">
        <div
          class="mx-auto wheel"
          :style="{ transform: `rotate(${startingRotation + rotationValue}deg)` }"
        >
          <canvas class="" id="canvas" width="270" height="270"> </canvas>
        </div>
        <img id="rim" src="@/pages/Wheel/Icons/rim.svg" class="wheel-rim" />
        <img class="arrow" src="@/pages/Wheel/Img/arrow.png" />
      </FlexRow>
      <FlexRow class="text-black font-bold mt-20 text-2xl text-center">
        Hold down the button longer for more power! 🔥
      </FlexRow>
      <FlexRow class="mt-10 flex justify-center">
        <Button
          class="basis-3/4 spin-btn"
          variant="mulah"
          size="lg"
          @mousedown="startPress"
          @mouseup="endPress"
          @mouseleave="cancelPress"
          @touchstart="startPress"
          @touchend="endPress"
          @touchcancel="cancelPress"
          @contextmenu.prevent
          >Spin the Wheel</Button
        >
      </FlexRow>
    </CardBody>
  </Card>

  <LimitReached :specification="specification" v-else />
  <ModalPrize
    :is-open="showPrizeModal"
    :title="prizeResult"
    :message="prizeMessage"
    :prize-result="prizeResult"
    :has-prize="hasPrize"
    :session-id="sessionId"
    @close-modal="showPrizeModal = false"
    @show-prize="handleShowPrizeList"
  />
  <ModalNoPrizeWon
    :spend-open="showNoPrizeWonModal"
    :end-date="endDate"
    @show-prizes="handleShowPrizeListFromSpendModal"
  />
  <!-- <ModalSpinInfo
    :show-info="true"
    :min-spend="minSpend"
    :end-date="endDate"
    @show-tnc="handleShowTnc"
    @show-prizes="handleShowPrizeList"
  /> -->

  <ModalExpired :is-expired="expiredModal" />
  <ModalDeactivated :is-deactivated="deactivatedModal" />
</template>

<script setup>
import { ref, computed, watch, onMounted } from 'vue'
import { useQuery, useMutation } from '@urql/vue'
import confetti from 'canvas-confetti'

import { useBrandStore } from '@/stores/store.js'
import { CHANCE_SESSION, RUN_CHANCE_CAMPAIGN } from '@/constants/graphql.js'

import Card from '@/components/uielements/Card/Card.vue'
import CardBody from '@/components/uielements/Card/CardBody.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import Button from '@/components/uielements/Button/Button.vue'
import ModalPrize from '@/pages/Wheel/Component/ModalPrizeResult.vue'
import ModalNoPrizeWon from '@/pages/Wheel/Component/ModalNoPrizeWon.vue'
import ModalSpinInfo from '@/pages/Wheel/Component/ModalSpinInfo.vue'
import InsufficientCreditPage from './Component/InsufficientCreditPage.vue'
import LimitReached from '@/pages/LimitReached/Index.vue'
import ModalExpired from '@/pages/Wheel/Component/ModalExpired.vue'
import ModalDeactivated from '@/pages/Wheel/Component/ModalDeactivated.vue'
import { useRouter, useRoute } from 'vue-router'

const brandStore = useBrandStore()
const endDate = ref('')
const expiry = ref(0)
const expiredModal = ref(false)
const deactivatedModal = ref(false)
const minSpend = ref(0)
const pressStartTime = ref(0)
const pressEndTime = ref(0)
const prizes = ref(null)
const prizeResult = ref(null)
const hasPrize = ref(true)
const prizeList = ref([])
const prizeListLength = ref(0)
const hasCredits = ref(false)
const hasMessage = ref(false)
const rotationValue = ref(0)
const sessionId = ref(null)
const showPrizeModal = ref(false)
const showTncModal = ref(false)
const showNoPrizeWonModal = ref(false)
const showPrizeList = ref(false)
const spinning = ref(false)
const tnc = ref('')
const showInsuffCreditsPage = ref(false)
const specification = ref(null)
const showLimitReached = ref(false)

const router = useRouter()
const route = useRoute()
const currencySymbol = {
  MY: 'RM',
  ID: 'Rp',
  SG: 'SGD'
}

onMounted(() => {
  sessionId.value = route.params.id

  const chanceQuery = useQuery({
    query: CHANCE_SESSION,
    variables: { id: sessionId.value }
  })

  watch(chanceQuery.fetching, (fetchStatus) => {
    if (!fetchStatus && chanceQuery.data.value) {
      const prizeLists = chanceQuery.data.value.slChanceSession.chanceOrganizer.chancePrizes
      const chanceOrganizerDetails = chanceQuery.data.value.slChanceSession.chanceOrganizer

      prizeList.value = prizeLists
      prizeListLength.value = prizeLists.length
      prizes.value = prizeLists.map((prizes) => prizes.name)
      minSpend.value = chanceOrganizerDetails.minSpend
      expiry.value = chanceOrganizerDetails.expiry
      endDate.value = chanceOrganizerDetails.endDate
      tnc.value = chanceOrganizerDetails.termsAndConditions

      drawWheel()
    }
  })
})

const { executeMutation: getPrize } = useMutation(RUN_CHANCE_CAMPAIGN)

//mutation

const handleGetPrize = async () => {
  const args = {
    sessionId: sessionId.value
  }

  try {
    const res = await getPrize(args)
    const result = res.data.slRunChanceCampaign.result
    if (result === 'EXPIRED') {
      expiredModal.value = true
      return
    }

    if (result === 'DEACTIVATED') {
      deactivatedModal.value = true
      return
    }

    if (result === 'INSUFFICIENT_CREDITS') {
      hasCredits.value = false
    } else {
      hasCredits.value = true
    }

    if (result === 'MESSAGE_LIMIT_REACHED') {
      hasMessage.value = false
    } else {
      hasMessage.value = true
    }

    if (result === 'ALREADY_REDEEMED') {
      if (minSpend.value === null) {
        alert(
          'You only get to spin the wheel once! Come back and spend at our store for another chance to spin! '
        )
      } else {
        alert(
          'You only get to spin the wheel once! Spend ' +
            currencySymbol[brandStore.countryCode] +
            minSpend.value +
            ' for another chance to spin it 🥳'
        )
      }

      router.push({ path: '/' })

      return
    }

    const prizeData = res.data.slRunChanceCampaign.prize
    if (prizeData) {
      hasPrize.value = res.data.slRunChanceCampaign.result === 'NO_PRIZE' ? false : true
      prizeResult.value = prizeData.name
    } else {
      hasPrize.value = false
      prizeResult.value = 'Better Luck Next Time'
    }
  } catch (e) {
    console.error(e)
    alert('Failed to get prize')
  }
}

// Functions

const cancelPress = () => {
  pressStartTime.value = 0
}

const closePrizeList = () => {
  showPrizeList.value = false
}

const drawWheel = () => {
  const canvas = document.getElementById('canvas')
  const ctx = canvas.getContext('2d')
  const width = canvas.width
  const height = canvas.height

  const centerX = width / 2
  const centerY = height / 2
  const radius = width / 2

  const step = 360 / prizeListLength.value
  const colors = []

  for (let i = 0; i < prizeListLength.value; i++) {
    colors.push(getColor(i))
  }

  draw()

  function draw() {
    ctx.clearRect(0, 0, width, height) // Clear canvas before drawing

    let startDeg = -120 // Start at -120 degrees so the first prize is at the top (0 degrees)
    for (let i = 0; i < prizeListLength.value; i++, startDeg += step) {
      let endDeg = startDeg + step

      let color = colors[i]
      let colorStyle = `rgb(${color.r},${color.g},${color.b})`

      // Draw colored segment
      ctx.beginPath()
      ctx.moveTo(centerX, centerY)
      ctx.arc(centerX, centerY, radius, toRad(startDeg), toRad(endDeg))
      ctx.fillStyle = colorStyle
      ctx.fill()

      // Calculate the angle for the text position
      let textAngle = (startDeg + endDeg) / 2

      // Position text slightly above the center, aligned with the widest part of the slice
      let textRadius = radius * 0.7 // Adjust this multiplier to position the text further up
      let textX = Math.cos(toRad(textAngle)) * textRadius
      let textY = Math.sin(toRad(textAngle)) * textRadius

      ctx.save()
      ctx.translate(centerX + textX, centerY + textY)
      ctx.rotate(toRad(textAngle + 90)) // Rotate text to align with the top edge
      ctx.textAlign = 'center'
      ctx.textBaseline = 'middle'
      ctx.fillStyle = color.r === 255 && color.g === 255 && color.b === 255 ? '#000000' : '#ffffff'
      ctx.font = 'bold 14px sans-serif'

      let text = prizes.value[i]
      let maxWidth = radius * 0.6

      // Wrap text within the available space
      let lines = wrapText(ctx, text, maxWidth, 3)
      let lineHeight = 18 // Adjust the line height as needed to add space between lines

      for (let j = 0; j < lines.length; j++) {
        ctx.fillText(lines[j], 0, j * lineHeight)
      }

      ctx.restore()

      // Draw divider line
      ctx.save()
      ctx.translate(centerX, centerY)
      ctx.rotate(toRad(startDeg))
      ctx.beginPath()
      ctx.moveTo(0, 0)
      ctx.lineTo(radius, 0)
      ctx.strokeStyle = 'white'
      ctx.lineWidth = 2
      ctx.stroke()
      ctx.restore()
    }
  }

  function toRad(deg) {
    return deg * (Math.PI / 180.0)
  }

  function wrapText(ctx, text, maxWidth, maxLines) {
    let words = text.split(' ')
    let lines = []
    let currentLine = words[0]

    for (let i = 1; i < words.length; i++) {
      let word = words[i]
      let width = ctx.measureText(currentLine + ' ' + word).width
      if (width < maxWidth) {
        currentLine += ' ' + word
      } else {
        lines.push(currentLine)
        currentLine = word
        if (lines.length === maxLines - 1) {
          break
        }
      }
    }

    lines.push(currentLine)

    // If the last line is still too wide, truncate with ellipsis
    if (ctx.measureText(lines[lines.length - 1]).width > maxWidth) {
      lines[lines.length - 1] = truncateText(lines[lines.length - 1], maxWidth)
    }

    return lines
  }

  function truncateText(text, maxWidth) {
    const ellipsis = '...'
    let truncated = text
    let textWidth = ctx.measureText(truncated).width

    while (textWidth > maxWidth - ctx.measureText(ellipsis).width && truncated.length > 0) {
      truncated = truncated.slice(0, -1)
      textWidth = ctx.measureText(truncated).width
    }

    return truncated + ellipsis
  }
}

const handlePressDuration = (pressDuration) => {
  const oscillationDuration = 1.5 // 1.5 seconds

  // Calculate the position within the full oscillation cycle (0 to 1.5s)
  let cyclePosition = pressDuration % (2 * oscillationDuration)

  // Adjust cyclePosition for the return direction (right to left)
  if (cyclePosition > oscillationDuration) {
    cyclePosition = 2 * oscillationDuration - cyclePosition
  }

  // Calculate the cyclePercentage based on the adjusted cyclePosition
  const cyclePercentage = (cyclePosition / oscillationDuration) * 100

  // Use the cyclePercentage to determine the spin speed
  const spinSpeed = mapPercentageToSpinSpeed(cyclePercentage)
  spinWheel(spinSpeed)
}

const endPress = () => {
  pressEndTime.value = performance.now()
  const pressDuration = (pressEndTime.value - pressStartTime.value) / 1000
  handlePressDuration(pressDuration)
}

function getColor(index) {
  if (prizeListLength.value === 7) {
    if (index === 6) {
      return { r: 150, g: 95, b: 12 } // Color yellow for the 7th index
    } else {
      // Color black and red for indices 0 to 5
      return index % 2 === 0 ? { r: 30, g: 30, b: 30 } : { r: 156, g: 13, b: 13 }
    }
  }
  // for odd prize list length
  else if (prizeListLength.value % 2 === 1) {
    if (index % 3 === 2) {
      return { r: 150, g: 95, b: 12 }
    } else if (index % 3 === 1) {
      return { r: 156, g: 13, b: 13 }
    } else {
      return { r: 30, g: 30, b: 30 }
    }
    // for even prize list length
  } else {
    if (index % 2 === 0) {
      return { r: 30, g: 30, b: 30 }
    } else {
      return { r: 156, g: 13, b: 13 }
    }
  }
}

const handleRedeemReward = async () => {
  if (hasMessage.value === false) {
    showLimitReached.value = true
    specification.value = 'app_free_campaign'
  } else if (hasCredits.value === false) {
    showLimitReached.value = true
    specification.value = 'app_paid_campaign'
  } else {
    setTimeout(() => {
      showPrizeModal.value = true
    }, 2000)
  }
}

function fire(particleRatio, opts) {
  confetti({
    ...defaults,
    ...opts,
    particleCount: Math.floor(count * particleRatio)
  })
}

const handleShowTnc = () => {
  showTncModal.value = true
}

function randomInRange(min, max) {
  return Math.random() * (max - min) + min
}

let count = 200
let defaults = {
  origin: { y: 0.7 }
}

const mapPercentageToSpinSpeed = (percentage) => {
  // Map the percentage to a spin speed value
  // For example, you can map 0% to 100% to a speed range
  // Change min speed if want spin duration from 0 to 1.5s to be bigger
  const minSpeed = 60
  const maxSpeed = 100

  return minSpeed + (maxSpeed - minSpeed) * ((100 - percentage) / 100)
}

const startPress = () => {
  pressStartTime.value = performance.now()
}

const spinWheel = (spinSpeed) => {
  if (spinning.value) return // If already spinning, do not start another spin
  spinning.value = true // Set spinning flag

  const initialDuration = 45000 / spinSpeed // The higher the value, the longer the duration of wheel spin
  const totalDuration = initialDuration * 10
  let startTime = performance.now()
  const initialRotation = startingRotation.value

  // Finds the index of the prize returned from the mutation and calculates the rotation needed for the pointer to land on
  const handlePrizeChange = (newValue) => {
    const prizeIndex = prizeList.value.findIndex((prize) => prize.name === newValue)

    if (prizeIndex === -1) {
      spinning.value = false // Reset spinning flag
      return
    }

    const step = 360 / prizeListLength.value
    const baseRotation = -prizeIndex * step
    const fullRotations = 30 * 360
    const targetRotation = baseRotation + fullRotations + step / 2

    let animationFrameId

    const rotateOverTime = (timestamp) => {
      const elapsedTime = timestamp - startTime
      let progress = elapsedTime / 2 / totalDuration
      progress = Math.min(progress, 1) // Ensure progress does not exceed 1

      // Apply easing function for smooth slowdown
      const easingProgress = 1 - Math.pow(3, -5.35 * progress)

      const currentRotation = initialRotation + (targetRotation - initialRotation) * easingProgress

      rotationValue.value = currentRotation - rotationOffset.value

      if (progress < 1) {
        // If progress < 1, wheel hasn't stopped spinning else, stopped
        animationFrameId = requestAnimationFrame(rotateOverTime)
      } else {
        if (newValue !== 'Better Luck Next Time') {
          if (hasPrize.value === true) {
            triggerConfetti()
            setTimeout(() => {
              handleRedeemReward()
            }, 2000)
          } else {
            handleRedeemReward()
          }
          spinning.value = false
        } else {
          setTimeout(() => {
            showNoPrizeWonModal.value = true
          }, 2000)
          spinning.value = false
        }
      }
    }

    animationFrameId = requestAnimationFrame(rotateOverTime)
  }

  // Watch for prize changes
  handleGetPrize() // Call mutation
  watch(prizeResult, handlePrizeChange)

  // Return function to cancel animation if needed
  return () => {
    if (animationFrameId) {
      cancelAnimationFrame(animationFrameId)
      spinning.value = false // Reset spinning flag
    }
  }
}

const triggerConfetti = () => {
  confetti({
    angle: randomInRange(55, 125),
    spread: randomInRange(50, 70),
    particleCount: randomInRange(50, 100),
    origin: { y: 0.6 }
  })

  setTimeout(() => {
    confetti({
      angle: randomInRange(55, 125),
      spread: randomInRange(50, 70),
      particleCount: randomInRange(50, 100),
      origin: { y: 0.6 }
    })
  }, 500)

  setTimeout(() => {
    fire(0.25, {
      spread: 26,
      startVelocity: 55
    })
    fire(0.2, {
      spread: 60
    })
    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    })
    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    })
    fire(0.1, {
      spread: 120,
      startVelocity: 45
    })
  }, 1000)

  let end = Date.now() + 15 * 1000
  let colors = ['#bb0000', '#ffffff']

  ;(function frame() {
    confetti({
      particleCount: 2,
      angle: 60,
      spread: 55,
      origin: { x: 0 },
      colors: colors
    })
    confetti({
      particleCount: 2,
      angle: 120,
      spread: 55,
      origin: { x: 1 },
      colors: colors
    })

    if (Date.now() < end) {
      requestAnimationFrame(frame)
    }
  })()
}

const prizeMessage = computed(
  () => `You've won ${prizeResult.value}. Check your reward to see all your prize.`
)

const rotationOffset = computed(() => {
  switch (prizeListLength.value) {
    case 3:
      return 30
    case 4:
      return 15
    case 5:
      return 6
    case 6:
      return 0
    case 7:
      return -5
    case 8:
      return -8
  }
})

const startingRotation = computed(() => {
  switch (prizeListLength.value) {
    case 3:
      return -30
    case 4:
      return -15
    case 5:
      return -6
    case 6:
      return 0
    case 7:
      return 4
    case 8:
      return 8
  }
})
</script>
<style scoped>
.wheel-container {
  background-color: white;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wheel-row {
  position: relative;
}

.wheel {
  position: relative;
  width: 270px;
  height: 270px;
  margin: 0 auto;
}

.wheel-rim {
  position: absolute;
  height: 350px;
  width: 350px;
  min-width: 350px;
  min-height: 350px;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  pointer-events: none;
}

.arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 130px;
  height: 130px;
  z-index: 2;
}

.spin-btn {
  user-select: none;
}

.spin-btn:active {
  animation: colorOscillation 1.5s infinite alternate;
  background: linear-gradient(90deg, #b91c1c 50%, #ff0000 50%);
  background-size: 200% 100%;
}

@keyframes colorOscillation {
  0% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}
</style>
