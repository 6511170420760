<template>
  <teleport to="body">
    <ModalBackdrop>
      <ModalContainer class="drop-shadow-2xl">
        <ModalBody class="space-y-0 px-3 pt-4 pb-0">
          <div class="border border-black rounded-lg px-3 py-4 mx-1">
            <p :style="brandPortalStore.textLinkColorStyle" class="font-semibold text-lg">
              Important
            </p>
            <ul
              :style="brandPortalStore.primaryTextColorStyle"
              class="list-disc list-inside text-base mt-1 leading-snug"
            >
              <li class="mt-3">
                <span class="font-semibold"
                  >Your review could take up to 7 days to be published by Google.</span
                >
              </li>
              <li class="mt-2 mb-1">
                <span class="font-semibold"
                  >Paste the provided # text you've copied into the review (Do not alter it).</span
                >
              </li>
            </ul>
          </div>

          <div class="flex ms-1" style="margin-top: 1rem">
            <p :style="brandPortalStore.primaryTextColorStyle" class="text-base">
              We'll notify you by SMS once your review is published!
            </p>
          </div>

          <hr style="margin-top: 12px; border-top: 0.9px solid gray" />

          <FlexRow style="margin-top: 1rem" class="items-center">
            <IconLightBulb class="mt-1 me-1" style="width: 18px; height: 18px" />
            <p :style="brandPortalStore.primaryTextColorStyle" class="text-base mt-1 leading-tight">
              Pro tips to get rewarded fast!
            </p>
          </FlexRow>
          <FlexRow style="margin-top: 0" class="items-center ms-2">
            <ul
              :style="brandPortalStore.primaryTextColorStyle"
              class="list-disc list-inside text-base mt-1 leading-tight"
            >
              <li class="mt-1"><span>Include a picture</span></li>
              <li class="mt-1"><span>Write a genuine review with at least 20 words</span></li>
            </ul>
          </FlexRow>

          <Button
            style="margin-top: 1.6rem"
            buttonStyle="primary"
            size="sm"
            class="w-full"
            @click="() => emit('go-to-google-review')"
          >
            Leave Review
          </Button>
          <Button
            style="margin-bottom: 1rem; margin-top: 0.3rem"
            buttonStyle="secondary"
            size="sm"
            class="w-full text-sm py-1"
            @click="() => emit('close-review-tips-modal')"
          >
            Cancel
          </Button>
        </ModalBody>
      </ModalContainer>
    </ModalBackdrop>
  </teleport>
</template>
<script setup>
import ModalBackdrop from '@/components/uielements/Modal/ModalBackdrop.vue'
import ModalContainer from '@/components/uielements/Modal/ModalContainer.vue'
import ModalBody from '@/components/uielements/Modal/ModalBody.vue'
import Button from '@/components/uielements/Button/Button.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import IconLightBulb from '@/components/icons/IconLightBulb.vue'
import { useBrandPortalStore } from '@/stores/store.js'

const brandPortalStore = useBrandPortalStore()
const props = defineProps({
  fullHashtag: {
    type: String,
    required: true
  }
})
const emit = defineEmits(['go-to-google-review', 'close-review-tips-modal'])
</script>

<style scoped>
li span {
  position: relative;
  left: -2px;
}

ul {
  list-style-position: outside;
}

li {
  margin-left: 16px;
}
</style>
