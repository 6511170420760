<template>
  <AccordianCollapse :accordian-id="'accordion-' + messageId">
    <Card
      class="pt-1 bg-white"
      :data-accordion-target="'#accordion-collapse-body-' + messageId"
      data-accordion-toggle="true"
      aria-expanded="false"
      :aria-controls="'accordion-collapse-body-' + messageId"
      style="cursor: pointer"
    >
      <div class="px-1 py-2.5 flex items-center justify-between">
        <span class="text-primary-black font-bold">
          <span class="font-poppinsBold text-sm" :style="brandPortalStore.textLinkColorStyle">
            {{ formatDateToPattern(props.messageObj.datetime) }}
          </span>
        </span>
        <svg
          data-accordion-icon
          class="w-3 h-3 shrink-0 transition ease-in-out duration-300 focus:rotate-180"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="#717171"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M1 1l4 4 4-4"
          />
        </svg>
      </div>

      <Card class="shadow-none w-64">
        <FlexRow class="gap-x-4 justify-between">
          <div class="items-center">
            <div v-if="messageObj.type === 'Expiry'">
              <p class="text-start font-inter text-xs text-primary-black">Credit</p>
              <p class="font-inter text-xs mt-2" :style="brandPortalStore.primaryTextColorStyle">
                +{{ messageObj.amount }}
              </p>
            </div>
            <div v-else-if="messageObj.type === 'Items Redeemed'">
              <p class="text-start font-inter text-xs text-primary-black">Redemption</p>
              <p class="font-inter text-xs mt-2" :style="brandPortalStore.primaryTextColorStyle">
                -{{ messageObj.amount }}
              </p>
            </div>
            <div v-else-if="messageObj.type === 'Voided'">
              <p class="text-start font-inter text-xs text-mulah-red">Voided</p>
              <p class="font-inter text-xs mt-2" :style="brandPortalStore.primaryTextColorStyle">
                Points Voided
              </p>
            </div>
          </div>
          <div class="gap-x-2 items-center">
            <p class="text-start font-inter text-xs text-primary-black">Balance Points</p>
            <p class="font-inter text-xs mt-2" :style="brandPortalStore.primaryTextColorStyle">
              {{ messageObj.balance.toLocaleString() }}
            </p>
          </div>
        </FlexRow>
      </Card>
      <AccordianBody
        :heading-id="'accordion-collapse-heading-' + messageId"
        :collapse-id="'accordion-collapse-body-' + messageId"
        utils="border-0 px-1 py-2"
      >
        <p
          class="text-primary-black font-poppinsBold text-sm mt-1"
          :style="brandPortalStore.textLinkColorStyle"
        >
          Details
        </p>
        <p class="basis-3/4 font-inter text-sm text-primary-black mt-3">
          {{ messageObj.type }}
        </p>
        <p
          class="basis-4/4 font-inter font-semibold text-sm mt-1"
          :style="brandPortalStore.primaryTextColorStyle"
        >
          {{ messageObj.content }}
        </p>
      </AccordianBody>
    </Card>
  </AccordianCollapse>
</template>
<script setup>
import AccordianCollapse from '@/components/uielements/Accordian/AccordianCollapse.vue'
import AccordianHeading from '@/components/uielements/Accordian/AccordianHeading.vue'
import AccordianBody from '@/components/uielements/Accordian/AccordianBody.vue'
import Card from '@/components/uielements/Card/Card.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import { formatDateToPattern } from '@/utils/dateUtils.js'

import { computed, defineProps } from 'vue'

import { useBrandPortalStore } from '@/stores/store.js'

const brandPortalStore = useBrandPortalStore()

const props = defineProps({
  messageId: {
    type: [String, Number],
    required: true
  },
  messageObj: {
    type: Object,
    required: true
  }
})
</script>

<style scoped></style>
