<template>
  <FlexCol class="w-full mt-5">
    <h3 :style="brandPortalStore.primaryTextColorStyle" class="font-bold font-inter my-2">
      Redeem Your Rewards
    </h3>
    <FlexCol
      class="gap-y-2.5 mb-12 relative"
      :class="{
        'overflow-y-hidden': !isRegistered,
        'overflow-y-auto': isRegistered
      }"
    >
      <template v-for="reward in getFilteredRewards" :key="reward.id">
        <RewardsCard :reward="reward" :user-points="userPoints" />
      </template>
      <template v-if="!isRegistered">
        <FlexRow class="absolute bottom-0 w-full">
          <FlexCol class="w-full">
            <div
              class="w-full h-24"
              style="
                background: linear-gradient(
                  0deg,
                  #fff 0%,
                  rgba(204, 204, 204, 0.01) 99.99%,
                  rgba(238, 238, 238, 0) 100%
                );
              "
            ></div>
            <FlexRow class="justify-center w-full bg-white pt-0.5">
              <Button utils="w-full" size="lg" @click="handleNextPage" buttonStyle="primary"
                >Login To Check Rewards</Button
              >
            </FlexRow>
          </FlexCol>
        </FlexRow>
      </template>
    </FlexCol>
  </FlexCol>
</template>
<script setup>
import FlexRow from '@/components/layout/FlexRow.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import Button from '@/components/uielements/Button/Button.vue'
import RewardsCard from './RewardsCard.vue'
import MobileLayout from '@/layout/MobileLayout/Index.vue'

import { computed } from 'vue'
import router from '../../router'
import { useBrandPortalStore, usePhoneStore } from '@/stores/store.js'

const brandPortalStore = useBrandPortalStore()
const phoneStore = usePhoneStore()

const props = defineProps({
  isRegistered: {
    type: Boolean,
    default: false
  },
  rewards: {
    type: Array,
    default: () => []
  },
  userPoints: {
    type: Number,
    default: 0
  }
})

const arrangeRewards = computed(() => {
  const arr = []
  const meetArr = [] //points < props.userPoints
  const exceedArr = [] //points > props.userPoints

  props.rewards.forEach((reward) => {
    if (reward.cost <= props.userPoints) {
      meetArr.push(reward)
    } else exceedArr.push(reward)
  })
  meetArr.sort((a, b) => a.cost - b.cost)
  exceedArr.sort((a, b) => a.cost - b.cost)

  arr.push(...meetArr)
  arr.push(...exceedArr)

  return arr
})

const getFilteredRewards = computed(() => {
  if (props.isRegistered) {
    return arrangeRewards.value
  } else return arrangeRewards.value.slice(0, 3)
})

const handleNextPage = () => {
  router.push({
    name: 'CheckPointsRewards'
  })
}
</script>
