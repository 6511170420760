<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <template v-if="props.showSideNav">
    <div class="relative" aria-labelledby="slide-over-title" role="dialog" style="z-index: 100">
      <div class="fixed inset-0 bg-gray-400 bg-opacity-75 transition-opacity"></div>
      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <div class="pointer-events-auto relative w-screen max-w-[18.5rem]">
              <FlexCol class="h-full overflow-y-scroll bg-white py-6 px-2 shadow-xl">
                <FlexCol utils="items-end px-2 h-full">
                  <button
                    type="button"
                    class="text-icon-grey focus:outline-none"
                    @click="closeSideNav"
                  >
                    <IconClose />
                    <span class="sr-only">Close menu</span>
                  </button>
                  <FlexCol utils="items-end w-full mt-12 mb-auto font-poppinsBold">
                    <h2 v-if="phoneStore.isLogin" class="text-2xl">
                      <router-link to="/view-details" @click="closeSideNav"
                        >Member&rsquo;s Info</router-link
                      >
                    </h2>
                    <h2 class="text-2xl whitespace-nowrap mt-6">
                      <router-link to="/tnc" @click="closeSideNav">Terms & Conditions</router-link>
                    </h2>
                    <h2 class="text-2xl mt-6">
                      <a
                        href="https://simpleloyalty.com/#/privacy-policy"
                        target="_blank"
                        @click="closeSideNav"
                      >
                        PDPA & Privacy
                      </a>
                    </h2>
                    <h2 class="text-2xl whitespace-nowrap mt-6">
                      <router-link to="/landing-search" @click="closeSideNav"
                        >Other Brands</router-link
                      >
                    </h2>
                  </FlexCol>
                </FlexCol>
              </FlexCol>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
</template>
<script setup>
import FlexCol from '@/components/layout/FlexCol.vue'
import IconClose from '@/components/icons/IconClose.vue'
import { defineProps, defineEmits, onMounted } from 'vue'
import { usePhoneStore } from '@/stores/store'

const phoneStore = usePhoneStore()

const props = defineProps({
  showSideNav: {
    type: Boolean,
    required: true
  }
})

const emits = defineEmits(['close-side-nav'])

const closeSideNav = () => {
  emits('close-side-nav')
}

onMounted(() => {})
</script>
