<template>
  <svg viewBox="0 0 81 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M40.5 80C62.5914 80 80.5 62.0914 80.5 40C80.5 17.9086 62.5914 0 40.5 0C18.4086 0 0.5 17.9086 0.5 40C0.5 62.0914 18.4086 80 40.5 80Z"
      :fill="bgColor"
      fill-opacity="0.3"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M50.7136 29.1382L36.0652 44.2124L31.0213 39.0227L27.6562 42.4858L36.0816 51.1566L54.0783 32.601L50.7136 29.1382Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M40.3566 74.7135C59.6075 74.7135 75.2134 59.1076 75.2134 39.8568C75.2134 20.6059 59.6075 5 40.3566 5C21.1058 5 5.49988 20.6059 5.49988 39.8568C5.49988 59.1076 21.1058 74.7135 40.3566 74.7135Z"
      :fill="bgColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M39.6667 24C48.8717 24 56.3333 31.4617 56.3333 40.6667C56.3333 49.8717 48.8717 57.3333 39.6667 57.3333C30.4617 57.3333 23 49.8717 23 40.6667C23 31.4617 30.4617 24 39.6667 24ZM39.6667 27.3333C36.1304 27.3333 32.7391 28.7381 30.2386 31.2386C27.7381 33.7391 26.3333 37.1304 26.3333 40.6667C26.3333 44.2029 27.7381 47.5943 30.2386 50.0948C32.7391 52.5952 36.1304 54 39.6667 54C43.2029 54 46.5943 52.5952 49.0948 50.0948C51.5952 47.5943 53 44.2029 53 40.6667C53 37.1304 51.5952 33.7391 49.0948 31.2386C46.5943 28.7381 43.2029 27.3333 39.6667 27.3333ZM39.6667 42.3333C41.7783 42.3333 43.7117 43.1217 45.18 44.4167C45.5115 44.7093 45.7132 45.1216 45.7407 45.563C45.7682 46.0043 45.6193 46.4385 45.3267 46.77C45.034 47.1015 44.6217 47.3032 44.1804 47.3307C43.739 47.3582 43.3049 47.2093 42.9733 46.9167C42.0613 46.1092 40.8847 45.6645 39.6667 45.6667C38.3983 45.6667 37.2417 46.1367 36.36 46.9167C36.1958 47.0614 36.0048 47.1725 35.7977 47.2434C35.5907 47.3144 35.3717 47.3438 35.1532 47.3301C34.9348 47.3164 34.7211 47.2598 34.5246 47.1636C34.328 47.0673 34.1523 46.9333 34.0075 46.7692C33.8627 46.605 33.7517 46.414 33.6807 46.2069C33.6098 45.9998 33.5803 45.7808 33.594 45.5624C33.6077 45.3439 33.6643 45.1303 33.7606 44.9337C33.8568 44.7371 33.9908 44.5614 34.155 44.4167C35.676 43.0722 37.6366 42.3311 39.6667 42.3333ZM33.8333 34C34.4964 34 35.1323 34.2634 35.6011 34.7322C36.0699 35.2011 36.3333 35.837 36.3333 36.5C36.3333 37.163 36.0699 37.7989 35.6011 38.2678C35.1323 38.7366 34.4964 39 33.8333 39C33.1703 39 32.5344 38.7366 32.0656 38.2678C31.5967 37.7989 31.3333 37.163 31.3333 36.5C31.3333 35.837 31.5967 35.2011 32.0656 34.7322C32.5344 34.2634 33.1703 34 33.8333 34ZM45.5 34C46.163 34 46.7989 34.2634 47.2678 34.7322C47.7366 35.2011 48 35.837 48 36.5C48 37.163 47.7366 37.7989 47.2678 38.2678C46.7989 38.7366 46.163 39 45.5 39C44.837 39 44.2011 38.7366 43.7322 38.2678C43.2634 37.7989 43 37.163 43 36.5C43 35.837 43.2634 35.2011 43.7322 34.7322C44.2011 34.2634 44.837 34 45.5 34Z"
      :fill="icon"
    />
  </svg>
</template>
<script setup>
import { computed } from 'vue'
import { useBrandPortalStore } from '@/stores/store.js'

const brandPortalStore = useBrandPortalStore()

const icon = computed(() => {
  return brandPortalStore.primaryButtonStyles.color
})

const bgColor = computed(() => {
  return brandPortalStore.primaryButtonStyles.backgroundColor
})
</script>
