<template>
  <teleport to="body">
    <ModalBackdrop>
      <div
        class="bg-gray-900 bg-opacity-50 fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full flex"
      >
        <div class="relative w-full max-w-md max-h-full m-auto">
          <div class="relative bg-white rounded-lg shadow">
            <ModalBody class="space-y-0 px-3 pt-4 pb-0">
              <div class="text-mulah-red flex justify-center">
                <IconCircleSuccess width="80" height="80" />
              </div>

              <p
                :style="brandPortalStore.primaryTextColorStyle"
                class="font-extrabold text-3xl flex justify-center text-center"
              >
                You are qualified!
              </p>
              <p
                class="text-xl"
                style="margin-top: 15px; text-align: center"
                :style="brandPortalStore.primaryTextColorStyle"
              >
                Follow the steps below and claim your prize!
              </p>

              <div class="w-full">
                <Button size="lg" class="w-full" @click="() => emit('ok')" buttonStyle="primary">
                  Ok
                </Button>
              </div>
              <p
                class="pb-5"
                style="text-align: center"
                :style="brandPortalStore.primaryTextColorStyle"
              >
                We'll send you your reward once you leave us a Google Review!
              </p>
            </ModalBody>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  </teleport>
</template>
<script setup>
import { ref, onMounted, watch } from 'vue'

import ModalBackdrop from '@/components/uielements/Modal/ModalBackdrop.vue'
import ModalBody from '@/components/uielements/Modal/ModalBody.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import Button from '@/components/uielements/Button/Button.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import IconCircleSuccess from '@/components/icons/IconCircleSuccess.vue'
import { useBrandPortalStore } from '@/stores/store.js'

const brandPortalStore = useBrandPortalStore()
const emit = defineEmits(['ok'])
</script>
