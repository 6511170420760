<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <FlexCol class="container min-h-screen" id="default-side-padding">
    <FlexCol class="w-full mt-10">
      <h1 :style="brandPortalStore.primaryTextColorStyle" class="title-3xl mt-5">
        Can I have your number?
      </h1>
      <h4
        :style="brandPortalStore.secondaryTextColorStyle"
        class="text-icon-grey font-normal text-base my-2"
      >
        Check Your Loyalty Points & Rewards
      </h4>
    </FlexCol>
    <FlexCol class="mb-4" :style="brandPortalStore.primaryTextColorStyle">
      <input
        id="intlPhone"
        type="tel"
        v-model="inputValue"
        class="border border-gray-300 text-md rounded-lg p-3 focus:ring-0 ring-offset-0 my-5 w-full"
        :style="{
          border: validPhoneNumber ? '2px solid black' : '2px solid #e0e0e0',
          color: brandPortalStore.primaryTextColorStyle.color
        }"
      />
    </FlexCol>
    <Button
      size="lg"
      class="w-full rounded-lg"
      :disabled="!validPhoneNumber"
      @click="validPhoneNumber ? sendOtp() : null"
      buttonStyle="primary"
    >
      Continue
    </Button>
    <Links class="mt-10" :links="portalLinks" />
  </FlexCol>
  <template v-if="showModal">
    <HitLimitModal @close-modal="showModal = false" :specification="specification" />
  </template>
</template>
<script setup>
import FlexRow from '@/components/layout/FlexRow.vue'
import MobileLayout from '@/layout/MobileLayout/Index.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import Button from '@/components/uielements/Button/Button.vue'
import Links from '@/pages/Login/Links.vue'
import { useMutation } from '@urql/vue'
import { useBrandStore, useBrandPortalStore } from '../../stores/store'
import { usePhoneStore } from '@/stores/store'
import { CHECKPOINTS_VALIDATION } from '@/constants/graphql.js'
import { ref, watch, computed, onMounted, nextTick } from 'vue'
import HitLimitModal from './HitLimitModal.vue'

import intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/css/intlTelInput.css'
import { AsYouType, isValidPhoneNumber } from 'libphonenumber-js/mobile'

import cookie from '@/utils/cookie.js'

const store = useBrandStore()
const phoneStore = usePhoneStore()
const brandPortalStore = useBrandPortalStore()
const selectedCountry = ref('MY')
const dialCode = ref(null)
const inputValue = ref('')
const validPhoneNumber = ref(false)
const phoneNumber = ref(null)
const fullPhoneNum = ref('')
const trunkPrefix = ref('0') // NOTE: https://en.wikipedia.org/wiki/Trunk_prefix
const pause = ref(false)
const { executeMutation } = useMutation(CHECKPOINTS_VALIDATION)
const emit = defineEmits(['nextPage'])
const portalLinks = ref([])

const processLinks = (links) => {
  if (Array.isArray(links) && links.length !== 0) {
    portalLinks.value = links
  }
}

const initializePhoneNumberInput = async () => {
  const input = document.querySelector('#intlPhone')

  const country = selectedCountry.value

  const iti = intlTelInput(input, {
    initialCountry: country,
    separateDialCode: true,
    utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js'
  })

  const getDialCode = (iti) => {
    dialCode.value = iti.getSelectedCountryData().dialCode
  }

  getDialCode(iti)

  input.addEventListener('countrychange', () => {
    getDialCode(iti)
    selectedCountry.value = iti.getSelectedCountryData().iso2.toUpperCase()
    inputValue.value = ''
  })
  pause.value = false
}

watch(inputValue, (newVal) => {
  const asYouType = new AsYouType(selectedCountry.value)
  validPhoneNumber.value = isValidPhoneNumber(`+${dialCode.value}${newVal}`)
  asYouType.input(newVal)
  phoneNumber.value = asYouType.getNationalNumber()
  fullPhoneNum.value = `+${dialCode.value}${newVal}`
})

watch(selectedCountry, (newVal) => {
  if (newVal === 'MY') {
    trunkPrefix.value = '0'
  } else {
    trunkPrefix.value = ''
  }
})

const showModal = ref(false)
const specification = ref(null)

const sendOtp = async () => {
  if (pause.value === false) {
    pause.value = true
    const result = await executeMutation({
      phoneNumber: phoneNumber.value,
      slug: store.slugUrl,
      specification: 'default',
      websiteToken: null,
      countryCode: selectedCountry.value
    })
    if (!result.error) {
      const data = result.data.slCheckpointsValidation
      let toPage = ''

      if (data.errors.length > 0) {
        if (data.errors[0].message === 'MESSAGE_LIMIT_REACHED') {
          showModal.value = true
          specification.value = 'app_free_verification'
          pause.value = false
          return
        } else if (data.errors[0].message === 'INSUFFICIENT_CREDITS') {
          showModal.value = true
          specification.value = 'app_paid_verification'
          pause.value = false
          return
        } else {
          return alert(data.errors[0].message)
        }
      } else if (data.registered === false) {
        toPage = 'Register'
      } else if (data.registered === true) {
        if (data.metaVerified === false) {
          toPage = 'Meta'
        } else if (data.metaVerified === true) {
          toPage = 'QualifiedRedemption'
        }
      }

      phoneStore.$patch({
        selectedCountry: selectedCountry.value,
        locationPath: toPage,
        trunkPrefix: trunkPrefix.value,
        phoneNumber: phoneNumber.value,
        fullPhoneNum: fullPhoneNum.value,
        metaVerified: data.metaVerified
      })

      emit('nextPage', 'SMSVerification')
    }
    pause.value = false
  }
}

onMounted(async () => {
  initializePhoneNumberInput()

  const gReviewPhoneNumCookie = 'gRevPNum'
  if (cookie.getCookie(gReviewPhoneNumCookie)) {
    let cookieValue = JSON.parse(cookie.getCookie(gReviewPhoneNumCookie))
    selectedCountry.value = cookieValue.cc
    await nextTick()
    inputValue.value = cookieValue.pNum
    initializePhoneNumberInput()
  }
  if (brandPortalStore.links) {
    processLinks(brandPortalStore.links)
  }
})

watch(
  () => brandPortalStore.links,
  (newValue) => {
    if (newValue) {
      processLinks(newValue)
    }
  }
)
</script>
