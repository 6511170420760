<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div v-if="customer.fetching.value">
    <h1 class="text-2xl font-poppinsBold text-primary-black text-center pb-2.5">Loading...</h1>
  </div>
  <div v-show="!customer.fetching.value">
    <div
      class="d-flex flex flex-col w-full relative container"
      :style="{
        background:
          brandPortalStore.membersInfoSpecification === 'color'
            ? brandPortalStore.membersInfoBgColor
            : null,
        height: store.memberships ? 'auto' : 'calc(6.5rem / 2)',
        overflow: store.memberships ? null : 'hidden'
      }"
      style="cursor: pointer"
    >
      <img
        v-if="brandPortalStore.membersInfoSpecification === 'photo'"
        :style="{
          opacity: `${brandPortalStore.membersInfoOpacity}%`,
          width: '100%',
          height: '6.5rem'
        }"
        :src="brandPortalStore.membersInfoPhotoUrl"
      />

      <div
        style="width: 100%; height: 100%; display: flex; flex-direction: column"
        :style="{
          position: brandPortalStore.membersInfoSpecification === 'photo' ? 'absolute' : 'relative'
        }"
      >
        <FlexCol class="justify-between" style="min-height: inherit" @click="redeemRewards">
          <FlexRow style="padding-top: 10px">
            <FlexCol class="w-full">
              <div
                class="flex justify-between items-center"
                style="padding-left: 1.25rem; padding-right: 1.25rem"
              >
                <h1
                  class="z-50 text-xl font-bold flex justify-start"
                  :style="brandPortalStore.membersInfoTextColorStyle"
                >
                  {{ customerNameText }}
                </h1>
                <div class="relative flex items-center z-50">
                  <Coin />
                  <span
                    class="z-50 text-lg font-bold ml-2"
                    :style="brandPortalStore.membersInfoTextColorStyle"
                  >
                    {{ userPoints.toLocaleString('en-US') }}
                    <span class="font-bold">Points</span>
                  </span>
                  <IconChevronRight
                    class="w-5 h-5 active-hover"
                    :color="brandPortalStore.membersInfoTextColorStyle.color"
                  />
                </div>
              </div>
            </FlexCol>
          </FlexRow>
        </FlexCol>

        <div
          v-if="store.memberships"
          class="py-2 grid grid-cols-12 gap-4 items-center relative z-50"
          style="
            cursor: pointer;
            padding-left: 1.25rem;
            padding-right: 1.25rem;
            background-color: rgba(255, 255, 255, 30%);
            margin-top: auto;
          "
          @click="handleViewMemberships"
        >
          <div class="z-50 col-span-3 flex items-center" v-if="store.memberships">
            <Badge
              class="me-1"
              :color="currentMembershipDetails.color"
              style="min-height: 1.5rem; min-width: 1.5rem; max-height: 1.5rem; max-width: 1.5rem"
            />
            <span class="me-1 font-bold text-sm" :style="brandPortalStore.membersInfoTextColorStyle"
              >{{ currentMembershipDetails.title }}
            </span>
            <div class="shrink-0 flex items-center justify-center active-hover">
              <IconChevronRight
                class="w-5 h-5"
                :color="brandPortalStore.membersInfoTextColorStyle.color"
              />
            </div>
          </div>

          <div class="col-span-9 ml-12 flex flex-col items-center" v-if="store.memberships">
            <div class="flex flex-col items-end w-full">
              <div class="flex items-center w-full">
                <span
                  class="z-50 text-xs flex-1 overflow-hidden whitespace-nowrap text-ellipsis"
                  :style="brandPortalStore.membersInfoTextColorStyle"
                  v-if="!maxTierReached"
                >
                  {{ curSym }} {{ pointsToNextTier }} to become a
                  {{ nextMembershipTierDetails.title }} member
                </span>
                <Badge
                  style="height: 20px; width: 20px"
                  class="ml-2 z-50"
                  :color="nextMembershipTierDetails.color"
                  v-if="!maxTierReached"
                />
              </div>
              <div class="flex items-center w-full mt-2">
                <ProgressBar
                  :width="pointsProgressBar"
                  :color="currentMembershipDetails.color"
                  class="z-50 w-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <template v-if="brandPortalStore.coverSpecification === 'color' && brandPortalStore.logoUrl">
    <div
      class="h-64 flex justify-center items-center"
      :style="{ background: brandPortalStore.coverColor }"
    >
      <img
        :src="brandPortalStore.logoUrl"
        class="text-center w-40 h-40 rounded-circle"
        style="border-radius: 50%"
        draggable="false"
      />
    </div>
  </template>
  <div
    v-else-if="brandPortalStore.coverSpecification === 'color' && !brandPortalStore.logoUrl"
    class="h-64 flex justify-center items-center"
    :style="{ background: brandPortalStore.coverColor }"
  >
    <div
      class="flex justify-center items-center"
      style="font-size: 5rem; height: 10rem; width: 10rem; border-radius: 50%; background: white"
    >
      {{ store.brandName.charAt(0) }}
    </div>
  </div>
  <template v-else>
    <img class="h-64" :src="brandPortalStore.coverPhotoUrl" />
  </template>
  <!-- <div class="flex justify-between mx-1 mt-10 mb-5">
        <p class="font-bold" :style="primaryTextColor">Redeem your rewards</p>
        <p class="font-bold" :style="primaryTextColor">See all</p>
      </div> -->
  <div id="default-side-padding">
    <p
      class="text-lg font-bold mt-5 mb-2"
      :style="brandPortalStore.primaryTextColorStyle"
      v-if="carouselSpaceArr.length > 0"
    >
      {{ brandPortalStore.carouselTitle }}
    </p>
    <div
      class="carousel-container flex overflow-x-auto overflow-y-hidden space-x-4"
      style="height: 280px"
      v-if="carouselSpaceArr.length > 0"
    >
      <template v-for="info in carouselSpaceArr" :key="info.index">
        <div>
          <Card
            v-if="info.enabled === true"
            class="active-hover flex flex-col p-0 w-48 mt-1.5 border-0 overflow-hidden"
            style="border-radius: 20px; height: 270px"
          >
            <a :href="redirectToUrl(info.url)" target="_blank">
              <div class="flex-grow">
                <img
                  v-if="info.imageUrl"
                  class="w-full rounded-t"
                  :src="info.imageUrl"
                  style="height: 9rem; object-fit: cover"
                />
                <div
                  v-else
                  class="flex justify-center items-center bg-neutral-200"
                  style="height: 144px; width: 192px"
                >
                  <div
                    class="flex justify-center items-center"
                    style="
                      font-size: 3rem;
                      height: 5rem;
                      width: 5rem;
                      border-radius: 50%;
                      background: white;
                    "
                  >
                    {{ store.brandName.charAt(0) }}
                  </div>
                </div>
              </div>

              <div class="px-3">
                <p
                  class="mt-3 font-semibold text-lg"
                  :style="brandPortalStore.primaryTextColorStyle"
                  style="line-height: 22px; overflow-wrap: break-word"
                >
                  {{ truncateCaption(info.caption) }}
                </p>
                <p class="mb-3 text-sm" :style="brandPortalStore.secondaryTextColorStyle">
                  {{ info.description }}
                </p>
              </div>
            </a>
          </Card>
        </div>
      </template>
    </div>
    <template v-if="activeGiveaways">
      <div class="flex mb-1 mt-5">
        <p class="font-bold text-lg" :style="brandPortalStore.primaryTextColorStyle">Giveaways</p>
      </div>
      <div
        class="carousel-container flex overflow-x-auto space-x-2 flex-shrink-0 w-full active-hover"
        @click="redirectToGiveaways(activeGiveaways.id)"
      >
        <Card class="p-0 my-1.5 border-0 flex h-48 rounded-lg" style="width: 100%">
          <div class="w-2/5">
            <img
              class="rounded-tl-md rounded-bl-md w-full h-full object-cover"
              :src="activeGiveaways.rewardImageUrl || DefaultPic"
            />
          </div>
          <div class="w-3/5 px-2 flex flex-col justify-around pt-2 ms-2">
            <p class="font-bold text-2xl mt-1 text-ellipsis overflow-hidden">
              Enjoy {{ activeGiveaways.title }}
            </p>
            <p class="text-sm">Ends on {{ formatDate(activeGiveaways.endTiming) }}</p>
            <p class="pb-2 mt-1 text-sm">
              {{ formatText(activeGiveaways.participants) }}
            </p>
          </div>
        </Card>
      </div>
    </template>
    <template v-if="activeChanceRecord">
      <p
        class="mb-2 mt-5 font-bold text-lg overflow-hidden"
        :style="brandPortalStore.primaryTextColorStyle"
      >
        Spin & Win
      </p>
      <div
        class="active-hover relative rounded-lg min-h-52"
        style="background-color: #383838"
        @click="redirectToWheelTnc"
      >
        <div class="flex items-start justify-between">
          <div class="flex flex-col flex-1">
            <p
              class="text-white text-lg font-bold w-full ms-5 mt-5"
              v-if="activeChanceRecord.mainPrizeName"
            >
              Win {{ activeChanceRecord.mainPrizeName }}
              <span class="text-red-700 text-xl font-bold">& More! </span>
            </p>
            <p class="ms-5 mt-2 text-white text-sm" v-if="activeChanceRecord.minSpend">
              When you spend a minimum of {{ curSym }}{{ activeChanceRecord.minSpend }}
            </p>
            <p class="text-white ms-5 mt-3 text-xs">
              Ends on {{ formatDate(activeChanceRecord.endDate) }}
            </p>
            <p class="text-white ms-5 text-xs mt-1 mb-3">
              {{ activeChanceRecord.participants }} Members have already spun the wheel
            </p>
          </div>
          <img :src="Wheel" class="wheel-image relative" />
        </div>
      </div>
    </template>
    <!-- <p class="my-5 font-bold" :style="primaryTextColor">Contests</p>
      <div
        class="gradient-bg flex flex-col justify-center h-32 rounded-lg w-full"
        v-for="(contest, idx) in activeContestsArr"
        :key="idx"
      >
        <p class="text-white ms-7 pb-3">
          Top {{ contest.winCriteria }} customers with the
          {{ formatContestText(contest.category) }}
        </p>
        <div class="flex justify-around mt-1 ps-3">
          <Trophy class="w-10 h-10" />
          <p
            class="text-white flex items-center font-bold text-lg text-ellipsis overflow-hidden w-3/6 whitespace-nowrap"
          >
            Win {{ contest.title }}!
          </p>
          <button class="rounded-md bg-white p-2 text-sm">Leaderboard</button>
        </div>
      </div> -->
    <template v-if="activeReviewRecord">
      <p class="mb-2 mt-5 font-bold text-lg" :style="brandPortalStore.primaryTextColorStyle">
        Google Review
      </p>
      <label class="active-hover" @click="redirectToReview(activeReviewRecord.id)">
        <div
          class="h-80 bg-white rounded-lg flex flex-col justify-center items-center"
          style="border: 1px solid #e8e7ec"
        >
          <div class="flex justify-center items-center w-full">
            <Google class="w-20 h-20" />
          </div>
          <div class="flex justify-center mt-1">
            <Star class="w-8 h-8" v-for="index in 5" :key="index" />
          </div>
          <div class="flex justify-center">
            <p class="font-bold text-2xl mt-5">{{ activeReviewRecord.title }}</p>
          </div>
          <p class="text-center mt-1 text-bold" v-if="activeReviewRecord.minSpend">
            Only for customers who have spent at least {{ curSym }}{{ activeReviewRecord.minSpend }}
          </p>
          <p class="text-center">
            {{ activeReviewRecord.participants }} people have joined this giveaway.
          </p>
        </div>
      </label>
    </template>

    <div class="mt-3">
      <Links :links="portalLinks" class="mb-20" />
    </div>
  </div>
</template>
<script setup>
import FlexRow from '@/components/layout/FlexRow.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import Card from '@/components/uielements/Card/Card.vue'
import Coin from '@/components/icons/IconCoin.vue'
import IconChevronRight from '@/components/icons/IconChevronRight.vue'
import Badge from '@/components/icons/IconBadge.vue'
import ProgressBar from '@/components/uielements/ProgressBar/ProgressBar.vue'
import Wheel from '@/assets/wheel-spin-and-win.png'
import Links from '@/pages/Login/Links.vue'
import Trophy from '@/components/icons/IconTrophy.vue'
import Google from '@/components/icons/IconGoogle.vue'
import Star from '@/components/icons/IconGStar.vue'
import IconDining from '@/components/icons/IconDining.vue'
import DefaultPic from '@/assets/images/quest/giveaway_default_pic.png'
import { usePhoneStore, useBrandStore, useBrandPortalStore } from '../../stores/store'
import { computed, ref, watch, watchEffect, onMounted, nextTick } from 'vue'
import { currencySymbol } from '@/constants/country_currency.js'
import router from '../../router'
import { useQuery } from '@urql/vue'
import {
  GET_CUSTOMER_POINTS,
  SL_ACTIVE_CHANCE,
  SL_ACTIVE_CONTESTS,
  SL_ACTIVE_GIVEAWAY,
  SL_ACTIVE_REVIEW
} from '@/constants/graphql.js'
import { formatDate } from '@/utils/dateUtils.js'
const activeGiveaways = ref(null)
const activeContestsArr = ref([])
const activeReviewRecord = ref([])
const activeChanceRecord = ref(null)
const name = ref('')
const userPoints = ref(0)
const currentMembershipDetails = ref({
  title: '',
  color: ''
})
const phoneStore = usePhoneStore()
const store = useBrandStore()
const brandPortalStore = useBrandPortalStore()
const carouselSpaceArr = ref([])
const customerTotalSpending = ref(null)
const portalLinks = ref([])
const maxTierReached = ref(false)
const nextMembershipTierDetails = ref({
  title: '',
  color: ''
})
const pointsToNextTier = ref(null)
const pointsProgressBar = ref(null)
const curSym = currencySymbol[store.countryCode]

const brandId = computed(() => store.brandId)

const customerNameText = computed(() =>
  name.value === 'Customer' ? 'Welcome!' : `Hi, ${name.value}`
)

const queryVariables = computed(() => ({
  slug: store.slugUrl,
  phoneNumber:
    phoneStore.trunkPrefix + phoneStore.phoneNumber !== '' &&
    phoneStore.trunkPrefix + phoneStore.phoneNumber !== null
      ? phoneStore.trunkPrefix + phoneStore.phoneNumber
      : phoneStore.phoneNumberWithPrefix,
  brandId: store.brandId
}))

const activeGiveawaysQuery = useQuery({
  query: SL_ACTIVE_GIVEAWAY,
  variables: {
    brandId: brandId
  },
  pause: computed(() => !store.brandId)
})

const activeContests = useQuery({
  query: SL_ACTIVE_CONTESTS,
  variables: {
    brandId: brandId
  },
  pause: computed(() => !store.brandId)
})
//
const activeReview = useQuery({
  query: SL_ACTIVE_REVIEW,
  variables: {
    brandId: brandId
  },
  pause: computed(() => !store.brandId)
})
//
const activeChance = useQuery({
  query: SL_ACTIVE_CHANCE,
  variables: {
    brandId: brandId
  },
  pause: computed(() => !store.brandId)
})

const customer = useQuery({
  query: GET_CUSTOMER_POINTS,
  variables: queryVariables,
  pause: computed(() => !store.brandId)
})

const handleViewMemberships = () => {
  router.push({
    name: 'Memberships'
  })
}

const processCarouselSpaces = (carousel) => {
  if (Array.isArray(carousel) && carousel.length > 0) {
    carouselSpaceArr.value = carousel
  }
}

const processLinks = (links) => {
  if (Array.isArray(links) && links.length > 0) {
    portalLinks.value = links
  }
}

const redeemRewards = () => {
  router.push({ name: 'QualifiedRedemption' })
}

const redirectToWheelTnc = () => {
  router.push({ name: 'SpinTheWheelTnc', params: { slug: store.slugUrl } })
}

const redirectToGiveaways = (id) => {
  router.push({ name: 'Giveaway', params: { slug: store.slugUrl, id: id } })
}

const redirectToReview = (reviewId) => {
  router.push({ name: 'GoogleReview', params: { slug: store.slugUrl, reviewId: reviewId } })
}

const redirectToUrl = (url) => {
  if (!url) return

  if (!/^https?:\/\//i.test(url)) {
    return 'https://' + url
  }
  return url
}

const formatContestText = (category) => {
  if (category === 'most_visits') {
    return 'Most Visits'
  } else if (category === 'most_spend') {
    return 'Most Spent'
  } else {
    return 'Most Points'
  }
}

const formatText = (val) => {
  return val === 1
    ? `${val} person has already participated in this giveaway!`
    : `${val} people have already participated in this giveaway!`
}

const formatGiveawayText = (val) => {
  return val === true ? 'Redeemed' : 'Redeem Now!'
}

const truncateCaption = (caption) => {
  if (!caption) return

  const maxLength = 45
  if (brandPortalStore.carouselSpecification === 'instagram' && caption.length > maxLength) {
    return caption.slice(0, maxLength) + '...'
  }
  return caption
}

watch(activeGiveawaysQuery.fetching, (fetchStatus) => {
  if (!fetchStatus) {
    if (activeGiveawaysQuery.data.value) {
      activeGiveaways.value = activeGiveawaysQuery.data.value.slActiveGiveaway
    }
  }
})

watch(activeContests.fetching, (fetchStatus) => {
  if (!fetchStatus) {
    if (activeContests.data.value) {
      activeContestsArr.value = activeContests.data.value.slActiveContests
    }
  }
})

watch(activeReview.fetching, (fetchStatus) => {
  if (!fetchStatus) {
    if (activeReview.data.value) {
      activeReviewRecord.value = activeReview.data.value.slActiveReview
    }
  }
})

watch(activeChance.fetching, (fetchStatus) => {
  if (!fetchStatus) {
    if (activeChance.data.value) {
      const records = activeChance.data.value?.slActiveChance
      activeChanceRecord.value = records
    }
  }
})
//

watch(customer.fetching, (fetchStatus) => {
  if (!fetchStatus) {
    const nextTier = customer.data.value?.slAppCustomer.additionalMembershipInfo?.nextTier
    const isMaxTier = nextTier ? false : true
    const progress = customer.data.value?.slAppCustomer.additionalMembershipInfo?.progress

    name.value = customer.data.value?.slAppCustomer.name
    currentMembershipDetails.value = customer.data.value?.slAppCustomer?.membership
    nextMembershipTierDetails.value = nextTier
    pointsToNextTier.value = customer.data.value?.slAppCustomer.additionalMembershipInfo?.missing
    pointsProgressBar.value = isMaxTier ? 100 : progress
    maxTierReached.value = isMaxTier
    customerTotalSpending.value = customer.data.value?.slAppCustomer.totalSpendingMembership
    customer.data.value?.slAppCustomer.brands.forEach((i) => {
      userPoints.value += i.totalAvailablePoints
    })
  }
})

watchEffect(() => {
  // Carousel spaces
  if (brandPortalStore.carouselSpaces && brandPortalStore.carouselSpaces.length > 0) {
    processCarouselSpaces(brandPortalStore.carouselSpaces)
  }

  if (brandPortalStore.carouselSpecification === 'instagram') {
    processCarouselSpaces(brandPortalStore.carouselSpacesInsta)
  } else {
    processCarouselSpaces(brandPortalStore.carouselSpacesCustom)
  }

  // Links
  if (brandPortalStore.links && brandPortalStore.links.length > 0) {
    processLinks(brandPortalStore.links)
  }
})

const membershipActivatedStyle = computed(() => {
  if (store.memberships) return `calc(6.5rem / 2)`

  return 'auto'
})
</script>

<style scoped>
.carousel-container::-webkit-scrollbar {
  display: none;
}

.active-hover {
  cursor: pointer;
}

.wheel-image {
  min-height: 12.5rem;
  margin-top: 0.7rem;
  width: 50%;
}

.gradient-bg {
  background: linear-gradient(150deg, #000000 60%, #333333 60%);
  width: 100%;
}
</style>
