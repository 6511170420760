<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="158"
    height="158"
    viewBox="0 0 158 158"
    fill="none"
  >
    <path
      :fill="bgColor"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M79 158C122.63 158 158 122.63 158 79C158 35.3695 122.63 0 79 0C35.3695 0 0 35.3695 0 79C0 122.63 35.3695 158 79 158Z"
      fill-opacity="0.3"
    />
    <path
      fill-rule="evenodd"
      fill="white"
      clip-rule="evenodd"
      d="M99.1713 57.5477L70.2407 87.3194L60.279 77.0697L53.6331 83.9094L70.2732 101.034L105.817 64.3868L99.1713 57.5477Z"
    />
    <path
      :fill="bgColor"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M78.8421 147.684C116.863 147.684 147.684 116.863 147.684 78.8421C147.684 40.8217 116.863 10 78.8421 10C40.8217 10 10 40.8217 10 78.8421C10 116.863 40.8217 147.684 78.8421 147.684Z"
    />
    <path
      fill-rule="evenodd"
      :fill="icon"
      clip-rule="evenodd"
      d="M96.4197 60.1483L71.209 86.0919L62.5283 77.1601L56.7368 83.1203L71.2374 98.0431L102.211 66.108L96.4197 60.1483Z"
    />
  </svg>
</template>
<script setup>
import { computed } from 'vue'
import { useBrandPortalStore } from '@/stores/store.js'

const brandPortalStore = useBrandPortalStore()

const icon = computed(() => {
  return brandPortalStore.primaryButtonStyles.color
})

const bgColor = computed(() => {
  return brandPortalStore.primaryButtonStyles.backgroundColor
})
</script>
