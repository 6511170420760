<template>
  <FlexCol class="min-h-screen pt-3 w-full whole-screen">
    <SearchBarWithClear v-model="searchInput" ref="searchBarRef"></SearchBarWithClear>
    <div v-if="searchInputLength >= 3 && searchResultsLength === 0 && searchInputLength !== 0">
      <FlexRow class="justify-center pt-3 w-full">
        <FlexCol>
          <FlexRow>
            <img src="@/assets/Search.svg" />
          </FlexRow>
          <FlexRow class="justify-center"> Sorry, no results found. </FlexRow>
        </FlexCol>
      </FlexRow>
    </div>
    <div v-else-if="searchInputLength < 3 && searchInputLength !== 0">
      <FlexRow class="justify-center pt-1 w-full px-2">
        <FlexRow>Please enter at least 3 characters to begin your search.</FlexRow>
      </FlexRow>
    </div>
    <div v-else-if="isLoading == true && !pause">
      <FlexRow class="justify-center pt-3 w-full">
        <FlexRow>Loading...</FlexRow>
      </FlexRow>
    </div>
    <div v-else>
      <FlexRow class="justify-stretch pt-3 w-full">
        <FlexCol class="container mt-2 mb-14 max-h-[70vh] justify-stretch card-container">
          <template v-for="brand in searchResults" :key="brand">
            <BrandCard :brand="brand" />
          </template>
        </FlexCol>
      </FlexRow>
    </div>
  </FlexCol>
</template>

<script setup>
import FlexRow from '@/components/layout/FlexRow.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import BrandCard from '../LandingSearch/BrandCard.vue'
import SearchBarWithClear from '../LandingSearch/SearchBarWithClear.vue'

import { ref, watch, computed, onMounted } from 'vue'
import { useQuery } from '@urql/vue'
import axios from 'axios'

import { BRAND_SEARCH_PUBLIC } from '@/constants/graphql.js'

const isLoading = ref(true)
const searchInput = ref('')
const searchResults = ref([])
const country = ref('MY')
const pause = computed(() => {
  return searchInput.value.length < 3
})

const searchQuery = useQuery({
  query: BRAND_SEARCH_PUBLIC,
  variables: {
    searchStr: searchInput
  },
  pause: pause
})

watch(searchQuery.fetching, (fetchStatus) => {
  if (!fetchStatus) {
    if (searchQuery.error.value) {
      return
    }

    const filteredResults = searchQuery.data.value.brandSearch
      .filter((obj) => obj.countryCode === country.value)
      .sort((a, b) => b.active - a.active)

    searchResults.value = filteredResults
    isLoading.value = false
  }
})

onMounted(() => {
  axios
    .get('https://api.country.is')
    .then((response) => {
      country.value = response.data.country
    })
    .catch((error) => {
      // Handle errors here
      console.error('Error fetching data:', error)
    })
})

const searchInputLength = computed(() => {
  return searchInput.value.length
})

const searchResultsLength = computed(() => {
  return searchResults.value.length
})

watch(searchInput, (newValue) => {
  if (newValue === '') {
    searchResults.value = []
  }
})
</script>
<style scoped>
.search-col {
  height: 80px !important;
}

.card-container {
  overflow-y: auto;
}

.whole-screen {
  margin: 0 !important;
}
</style>
