<template>
  <teleport to="body">
    <ModalBackdrop>
      <ModalContainer class="drop-shadow-2xl">
        <ModalBody class="space-y-0 px-3 py-0">
          <FlexCol class="items-center mb-2">
            <h4
              class="font-bold text-lg text-center mt-4"
              :style="brandPortalStore.primaryTextColorStyle"
            >
              Example Review
            </h4>
          </FlexCol>
          <FlexRow class="mt-5" style="margin-top: 0.5rem">
            <FlexCol class="w-1/6 items-center justify-left h-full">
              <IconPerson style="width: 70px; height: 70px" />
            </FlexCol>
            <FlexCol class="w-5/6 ms-1 justify-center">
              <FlexRow>
                <p class="font-medium text-sm">You</p>
              </FlexRow>
              <FlexRow>
                <p class="font-normal text-gray-500 text-xs mt-0.5">91 Reviews 60 Photos</p>
              </FlexRow>
              <FlexRow>
                <template v-for="n in 5" :key="n">
                  <IconStarFill style="color: #fabb05" class="me-0.5 mt-0.5" />
                </template>
              </FlexRow>
            </FlexCol>
          </FlexRow>
          <FlexRow style="margin-top: 0.5rem" :style="brandPortalStore.primaryTextColorStyle">
            <p class="text-base mt-1">
              Great selection and friendly staff make visiting here enjoyable. Prices could be more
              competitive, but overall a pleasant experience!
            </p>
          </FlexRow>

          <FlexRow style="margin-top: 0.5rem">
            <p class="text-sm break-words" :style="brandPortalStore.textLinkColorStyle">
              {{ fullHashtag }}
            </p>
          </FlexRow>

          <FlexRow style="margin-top: 0.9rem">
            <img src="https://picsum.photos/id/163/200/200" style="width: 100px" />
            <img src="https://picsum.photos/id/225/200/200" style="width: 100px" />
            <img src="https://picsum.photos/id/250/200/200" style="width: 100px" />
          </FlexRow>
          <Button
            style="margin-top: 1rem; margin-bottom: 1rem"
            buttonStyle="primary"
            size="sm"
            class="w-full"
            @click="() => emit('close-modal')"
          >
            Done
          </Button>
        </ModalBody>
      </ModalContainer>
    </ModalBackdrop>
  </teleport>
</template>
<script setup>
import ModalBackdrop from '@/components/uielements/Modal/ModalBackdrop.vue'
import ModalContainer from '@/components/uielements/Modal/ModalContainer.vue'
import ModalBody from '@/components/uielements/Modal/ModalBody.vue'
import Button from '@/components/uielements/Button/Button.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import IconClose from '@/components/icons/IconClose.vue'
import IconPerson from '@/components/icons/IconPerson.vue'
import IconStarFill from '@/components/icons/IconStarFill.vue'
import { useBrandPortalStore } from '@/stores/store.js'

const brandPortalStore = useBrandPortalStore()
const props = defineProps({
  fullHashtag: {
    type: String
  }
})

const emit = defineEmits(['close-modal'])
</script>
