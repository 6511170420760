<template>
  <template v-if="store.brandQueryFetching">
    <FlexRow class="justify-center">
      <FlexCol class="grid h-screen place-items-center">
        <Spinner :width="16" :height="16" />
      </FlexCol>
    </FlexRow>
  </template>
  <template v-else>
    <div
      class="container"
      :style="{
        background:
          brandPortalStore.membersInfoSpecification === 'color'
            ? brandPortalStore.membersInfoBgColor
            : null
      }"
      :class="brandPortalStore.membersInfoSpecification === 'photo' ? 'has-bg' : null"
    >
      <div
        v-if="customerTotalSpending === 0"
        style="display: flex; flex-wrap: wrap; align-items: center; padding: 1rem"
      >
        <div style="flex: 0 0 auto">
          <ArrowLeft
            class="z-50 relative text-white w-6 h-6"
            @click="handleBackToPrevPage"
            :style="brandPortalStore.membersInfoTextColorStyle"
          />
        </div>
        <div style="flex: 1; display: flex; justify-content: center">
          <span class="z-50 font-bold text-xl" :style="brandPortalStore.membersInfoTextColorStyle"
            >Membership Levels</span
          >
        </div>
        <div
          class="w-full mt-7 flex flex-wrap"
          :class="{
            'justify-center': membershipTiers.length === 1,
            'justify-around': membershipTiers.length >= 2
          }"
        >
          <div
            v-for="(tier, index) in membershipTiers"
            class="flex flex-col justify-center items-center w-1/3 mb-4"
            :key="index"
          >
            <Badge
              class="z-50 active-hover w-12 h-12 flex justify-center"
              :color="tier.color"
              @click="switchTab(tier.name)"
            />
            <p class="z-50 font-bold mt-3" :style="brandPortalStore.membersInfoTextColorStyle">
              {{ tier.name }}
            </p>
            <p class="z-50 text-sm" :style="brandPortalStore.membersInfoTextColorStyle">
              {{ tier.threshold }} Points
            </p>
          </div>
        </div>
      </div>
      <div v-else style="display: flex; flex-wrap: wrap; align-items: center; padding: 1rem">
        <div style="flex: 0 0 auto">
          <ArrowLeft
            class="z-50 relative active-hover text-white w-6 h-6"
            @click="handleBackToPrevPage"
            :style="brandPortalStore.membersInfoTextColorStyle"
          />
        </div>
        <div style="flex: 1; display: flex; justify-content: center">
          <span class="z-50 font-bold text-xl" :style="brandPortalStore.membersInfoTextColorStyle"
            >Membership Levels</span
          >
        </div>
        <div class="w-full mt-7 flex flex-wrap justify-between items-center">
          <div class="flex items-center">
            <Badge
              class="z-50 w-12 h-12 flex justify-center"
              :color="currentMembershipDetails.color"
            />
            <div class="ms-3">
              <p
                class="font-bold text-xl z-50 relative"
                :style="brandPortalStore.membersInfoTextColorStyle"
              >
                {{ currentMembershipDetails.title }}
              </p>
              <p class="z-50 text-sm relative" :style="brandPortalStore.membersInfoTextColorStyle">
                {{ customerTotalSpending }} Points
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="z-50 relative flex justify-between w-full py-3 px-3"
        style="background-color: rgba(255, 255, 255, 30%)"
      >
        <p class="z-50 flex items-center" :style="brandPortalStore.membersInfoTextColorStyle">
          {{ curSym }} {{ pointsToNextTier }} to become a {{ tierToShow.title }} member
        </p>
        <Badge class="w-6 h-6 z-50" :color="tierToShow.color" />
      </div>
      <div class="pb-4 px-3 z-50 relative" style="background-color: rgba(255, 255, 255, 30%)">
        <ProgressBar
          :width="pointsProgressBar"
          :color="currentMembershipDetails.color"
          class="z-50 relative"
        />
      </div>
    </div>
    <div
      class="container overflow-y-auto min-h-screen"
      style="padding-top: 0"
      id="default-side-padding"
    >
      <div class="container w-full h-12 bg-gray-300 rounded-3xl mt-4">
        <div class="flex justify-between items-center h-full">
          <button
            v-for="(items, index) in visibleMemberships"
            :id="items.name"
            :key="items.id"
            :class="[
              'btn flex-1 mx-2 text-center h-4/5 rounded-3xl',
              animateClass,
              activeTab === items.name ? 'bg-black text-white' : 'bg-gray-300 text-black'
            ]"
            @click="switchTab(items.name)"
          >
            {{ items.name }}
          </button>
        </div>
      </div>
      <p class="mt-5 font-bold ms-4">Member Benefits</p>
      <template v-for="(benefits, _index) in membershipBenefitsByTiers" :key="_index">
        <div class="flex mt-5" v-if="benefits.category === 'tier'">
          <Tick class="w-10 h-10 mx-3" :value="benefits.details.value" />
          <p
            class="flex items-center font-semibold"
            :style="
              benefits.details.value === '1'
                ? brandPortalStore.primaryTextColorStyle
                : brandPortalStore.secondaryTextColorStyle
            "
          >
            {{ benefits.benefitName }}
          </p>
        </div>
      </template>
    </div>
  </template>

  <MobileFooter class="mx-0" :active="'Rewards'" />
</template>

<script setup>
import { ref, computed, watch, watchEffect } from 'vue'
import { useQuery } from '@urql/vue'

import { useBrandStore, usePhoneStore, useBrandPortalStore } from '../../stores/store'
import { GET_CUSTOMER_POINTS } from '@/constants/graphql.js'
import { currencySymbol } from '@/constants/country_currency.js'
import router from '../../router'

import ArrowLeft from '@/components/icons/IconArrowLeft.vue'
import Badge from '@/components/icons/IconBadge.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import MobileFooter from '@/layout/MobileFooter/Index.vue'
import ProgressBar from '@/components/uielements/ProgressBar/ProgressBar.vue'
import Spinner from '@/components/reusable/Spinner.vue'
import Tick from '@/components/icons/IconTick.vue'

const store = useBrandStore()
const phoneStore = usePhoneStore()
const brandPortalStore = useBrandPortalStore()

const activeTab = ref(null)
const animateClass = ref('')
const curSym = currencySymbol[store.countryCode]
const customerTotalSpending = ref(null)
const currentMembershipDetails = ref({
  title: '',
  color: ''
})
const maxMembershipTier = ref({
  title: '',
  color: ''
})
const maxTierReached = ref(false)
const membershipBenefits = ref([])
const membershipBenefitsByTiers = ref([])
const membershipTiers = ref([])
const nextMembershipTier = ref({
  title: '',
  color: ''
})
const pointsToNextTier = ref(null)
const pointsProgressBar = ref(null)

const handleBackToPrevPage = () => {
  router.push({
    name: 'Login'
  })
}

const processMembershipInfo = (membershipInfo) => {
  if (Array.isArray(membershipInfo.membershipTiers) && membershipInfo.length !== 0) {
    membershipBenefits.value = membershipInfo.membershipBenefits
    membershipTiers.value = membershipInfo.membershipTiers
      .sort((a, b) => a.rank - b.rank)
      .map((tier) => ({
        name: tier.name,
        rank: tier.rank,
        color: tier.color,
        threshold: tier.threshold
      }))
  }
}

const switchTab = (name) => {
  activeTab.value = name

  const filteredBenefits = membershipBenefits.value
    .map((benefit) => {
      const tierValue = benefit.tierValues.find((tierValue) => tierValue.tierName === name)
      if (tierValue) {
        return {
          benefitName: benefit.name,
          category: benefit.category,
          details: {
            name: tierValue.tierName,
            value: tierValue.value
          }
        }
      }
      return null
    })
    .filter((b) => b !== null)

  sortBenefitsByTierValue(filteredBenefits)
}

const sortBenefitsByTierValue = (filteredBenefits) => {
  const sortedBenefits = filteredBenefits
    .filter((b) => !isNaN(parseInt(b.details.value, 10)))
    .sort((a, b) => {
      const valueA = parseInt(a.details.value, 10)
      const valueB = parseInt(b.details.value, 10)
      return valueB - valueA
    })

  membershipBenefitsByTiers.value = sortedBenefits
  animateClass.value = 'animate-slide'

  setTimeout(() => {
    animateClass.value = ''
  }, 500)
}

const tierToShow = computed(() =>
  maxTierReached.value ? maxMembershipTier.value : nextMembershipTier.value
)

const queryVariables = computed(() => ({
  slug: store.slugUrl,
  phoneNumber:
    phoneStore.trunkPrefix + phoneStore.phoneNumber !== '' &&
    phoneStore.trunkPrefix + phoneStore.phoneNumber !== null
      ? phoneStore.trunkPrefix + phoneStore.phoneNumber
      : phoneStore.phoneNumberWithPrefix,
  brandId: store.brandId
}))

const visibleMemberships = computed(() => {
  const index = membershipTiers.value.findIndex((m) => m.name === activeTab.value)
  const end = Math.min(membershipTiers.value.length, index + 2)
  const adjustedStart = Math.max(0, end - 3)
  return membershipTiers.value.slice(adjustedStart, adjustedStart + 3)
})

const customer = useQuery({
  query: GET_CUSTOMER_POINTS,
  variables: queryVariables,
  pause: computed(() => !store.brandId)
})

watch(customer.fetching, (fetchStatus) => {
  if (!fetchStatus && customer.data.value) {
    const nextTier = customer.data.value?.slAppCustomer.additionalMembershipInfo?.nextTier
    const isMaxTier = nextTier ? false : true
    const maxTier = customer.data.value?.slAppCustomer.additionalMembershipInfo?.maxTier
    const progress = customer.data.value?.slAppCustomer.additionalMembershipInfo?.progress

    maxMembershipTier.value = maxTier
    maxTierReached.value = isMaxTier
    nextMembershipTier.value = nextTier
    pointsToNextTier.value = customer.data.value?.slAppCustomer.additionalMembershipInfo?.missing
    pointsProgressBar.value = isMaxTier ? 100 : progress
    currentMembershipDetails.value = customer.data.value?.slAppCustomer?.membership
    customerTotalSpending.value = customer.data.value.slAppCustomer.totalSpendingMembership
  }
})

watch(customerTotalSpending, (newValue) => {
  if (newValue !== 0) {
    switchTab(currentMembershipDetails.value.title)
  } else {
    switchTab(maxMembershipTier.value.title) // Switch to highest tier if customer spending = 0
  }
})

watchEffect(() => {
  if (store.memberships) {
    processMembershipInfo(store.memberships)
  }
})
</script>

<style scoped>
@keyframes slide {
  from {
    transform: translateX(20px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

.animate-slide {
  animation: slide 0.5s;
}

.active-hover:hover {
  cursor: pointer;
}

.has-bg {
  position: relative;
  background-color: white;
}

.has-bg:after {
  content: '';
  background: v-bind('brandPortalStore.membersInfoBgPhoto.url');
  background-size: cover;
  background-position: top left;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  cursor: pointer;
  opacity: v-bind('brandPortalStore.membersInfoBgPhoto.opacity');
}
</style>
