<template>
  <teleport to="body">
    <ModalBackdrop>
      <div
        class="bg-gray-900 bg-opacity-50 fixed top-0 left-0 right-0 z-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full flex"
      >
        <div class="relative w-full max-w-md max-h-full m-auto">
          <div class="relative bg-white rounded-lg shadow">
            <ModalBody class="space-y-0 px-3 pt-4 pb-0">
              <div class="text-mulah-red flex justify-center">
                <SadSvg width="80" height="80" />
              </div>

              <p
                :style="brandPortalStore.primaryTextColorStyle"
                class="font-extrabold text-3xl flex justify-center text-center"
              >
                Whoops!
              </p>

              <FlexRow
                class="flex flex-col text-center"
                style="margin-bottom: 20px; margin-top: 20px"
              >
                <p :style="brandPortalStore.primaryTextColorStyle" class="text-xl">
                  Please spend at least {{ props.currencyCode }}{{ props.minSpend }} at
                  {{ props.outletName }} to claim your reward
                </p>
              </FlexRow>

              <div class="w-full">
                <Button
                  buttonStyle="secondary"
                  size="lg"
                  class="w-3/12 me-5"
                  @click="() => emit('go-back')"
                >
                  <template #icon>
                    <IconArrowLeft class="my-[0.4rem]" />
                  </template>
                </Button>
                <Button buttonStyle="primary" size="lg" class="mb-5 w-8/12" @click="clickedDone">
                  Done
                </Button>
              </div>
            </ModalBody>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  </teleport>
</template>
<script setup>
import { ref, onMounted, watch } from 'vue'

import ModalBackdrop from '@/components/uielements/Modal/ModalBackdrop.vue'
import ModalBody from '@/components/uielements/Modal/ModalBody.vue'
import FlexCol from '@/components/layout/FlexCol.vue'
import Button from '@/components/uielements/Button/Button.vue'
import FlexRow from '@/components/layout/FlexRow.vue'
import SadSvg from '@/components/icons/IconSadSvg.vue'
import Timer from '@/pages/GoogleReview/Components/Timer.vue'
import IconArrowLeft from '@/components/icons/IconArrowLeft.vue'
import { useBrandPortalStore } from '@/stores/store.js'

const brandPortalStore = useBrandPortalStore()

const clickedDone = () => {
  emit('done')
}

const props = defineProps({
  currencyCode: {
    type: String
  },
  minSpend: {
    type: Number
  },
  outletName: {
    type: String
  }
})
const emit = defineEmits(['go-back', 'done'])
</script>
